import * as React from 'react';
import {
    GridOverlay,
    DataGrid,
    getGridNumericColumnOperators,
    GridToolbar,
    GridToolbarContainer,
    GridColumnsToolbarButton,
    GridFilterToolbarButton,
    GridToolbarExport,
    GridDensitySelector
} from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import {gql} from "@apollo/client/core";
import {useQuery} from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import OrderCreateSummary from "./OrderCreateSummary";
import {makeStyles} from "@material-ui/core";
import OrderVariableDialog from "./OrderVariableDialog";
import CardActions from "@material-ui/core/CardActions/CardActions";
import OrderVariableEditForm from "./OrderVariableEditForm";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import OrderVariableCard from "./OrderVariableCard";



function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));


const columns = [
    { field: 'colour', headerName: 'Colour', flex: 0.75,editable: false },
    { field: 'width', headerName: 'Width',  flex: 0.5, type: 'number', editable: false },
    { field: 'depth', headerName: 'Drop',  flex: 0.5, type: 'number', editable: false },
    { field: 'shelfQuantity', headerName: 'Current Stock Level',  flex: 1, type: 'number', editable: false },
    { field: 'inTransit', headerName: 'In Transit',  flex: 1, type: 'number', editable: false },
    { field: 'weeklyAve', headerName: 'Avg Weekly Usage',  flex: 1, type: 'number', editable: false },
    { field: 'qtyToReorder', headerName: 'Qty To Reorder', flex: 1, type: 'number', editable: true },
];

const GET_STOCK_ORDER_QUERY = gql`
query{
   stockToOrders{
    totalCount
    nodes{
      id
      fabric
      colour
      width
      depth
      qtyToReorder
      baseBar
      bracket
      chainLength
      rollDirection
      controlSide
      sticker
      shelfQuantity
      inTransit
      weeklyAve
    }
  }
}
`;

export default function OrderLinesTable() {
    const classes = useStyles();
    const { data, loading, error, refetch } = useQuery(GET_STOCK_ORDER_QUERY, {
        notifyOnNetworkStatusChange: true
    });
    const [rows, setRows] = React.useState([]);
    const [qtySwitch, setQtySwitch] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState({ items: [],});

    React.useEffect(() => {
        if(data){setRows(data.stockToOrders.nodes)}
    }, [data]);
    const refetchStockOrder = () => {
      refetch();
    };
    const handleSwitchChange = (event) => {
        setQtySwitch( event.target.checked );
        if(event.target.checked){
            setFilterModel({
                items: [
                    { columnField: 'qtyToReorder', operatorValue: '>', value: '0' },
                ],
            })
        }
        else {
            setFilterModel({
                items: [],
            })
        }
    };

    const handleEditCellChangeCommitted = React.useCallback(
        ({ id, field, props }) => {
            if (field === 'qtyToReorder') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const qty = parseInt(data.value);
                const updatedRows = rows.map((row) => {
                    if (row.id === id) {
                        return { ...row, qtyToReorder:qty };
                    }
                    return row;
                });
                setRows(updatedRows);
            }
        },
        [rows],
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridColumnsToolbarButton />
                <GridFilterToolbarButton />
                <GridDensitySelector />
                <FormControlLabel
                    control={
                        <Switch
                            checked={qtySwitch}
                            onChange={handleSwitchChange}
                            name="qtySwitch"
                            color="primary"
                        />
                    }
                    label="Hide 0 Qty To Reorder"
                />
            </GridToolbarContainer>
        );
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7} lg={7}>
                <Grid container>
                    <Grid item xs>
                        <div className={classes.root} style={{ height: '85vh', width: '100%', display: 'flex'}}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={100}
                                    rowCount={data? data.stockToOrders.totalCount: 0}
                                    onEditCellChangeCommitted={handleEditCellChangeCommitted}
                                    loading={loading}
                                    density="compact"
                                    filterModel={filterModel}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        LoadingOverlay: CustomLoadingOverlay,
                                    }}

                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5}>
                <div className={classes.card}>
                    <OrderCreateSummary records={rows} loading={loading} refetchStockOrder={refetchStockOrder}/>
                </div>
                <div className={classes.card}>
                    <OrderVariableCard refetchStockOrder={refetchStockOrder} refetchQuery={GET_STOCK_ORDER_QUERY}/>
                </div>
            </Grid>
        </Grid>

    );
}


