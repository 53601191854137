import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {useSnackbar} from "notistack";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const DOCUMENT_DELETE_MUTATION = gql`
    mutation($id: Int!){
      deleteWarehouseDocument(input: { id: $id }) {
        deletedWarehouseDocumentNodeId
        warehouseDocument{
        id
            filePath
        }
        
      }
    }
`;


export default function FileList(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [dense, setDense] = React.useState(false);

    const [deleteDocument, {loading: mutationLoading, error: mutationError}] = useMutation(DOCUMENT_DELETE_MUTATION,{
        onCompleted: ({deleteWarehouseDocument}) => {
            props.handleDocumentDeleted(deleteWarehouseDocument.warehouseDocument.id);
        }
    });
    React.useEffect(() => {
        mutationError && enqueueSnackbar(`Server encountered an error: ${mutationError.message}`, {variant: "error"})
    }, [mutationError]);


    const handleDelete = (id) => {
        deleteDocument({
            variables: {
                id: id
            }
        })
    };
    const getDownloadFileURL = (fileName) => {
        return `https://nwmknhv3y9.execute-api.ap-southeast-2.amazonaws.com/dev/s3/uploads/${fileName}`;
    };
    return (
        <div className={classes.root}>
             <Typography variant="h6" className={classes.title}>
            Documents
            </Typography>
                {props.documents.length > 0?
                <React.Fragment>
                <div className={classes.demo}>
                <List dense={dense}>
                {props.documents.map(document => (
                    <ListItem key={document.id}>
                        <ListItemAvatar>
                            <Avatar>
                                <DescriptionIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={document.fileName}
                            secondary={document.fileType}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" href={getDownloadFileURL(document.filePath)} download={true}>
                                <GetAppIcon/>
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(document.id)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
                </List>
                </div>
                </React.Fragment>: null
                }
        </div>
    );
}
