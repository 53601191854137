import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core";
import StatusList from "./StatusList";
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function StatusTabList(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    let statusTabCount = {};
    props.statusList.map(status => statusTabCount[`tab-${status.name.toLowerCase()}`] = 0);
    const [tabCount, setTabCount] = React.useState(statusTabCount);
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleTotalCount(statusName, count) {
        setTabCount({
            ...tabCount,
            [`tab-${statusName.toLowerCase()}`] : count
        });
    }
    return (
        <div className={classes.root}>
            <Paper square elevation={2}>
                <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="simple tabs example">
                    {props.statusList.map((status, index) =>
                        <Tab  key={`${status.name}-${index}-tab`} label={tabCount[`tab-${status.name.toLowerCase()}`] > 0 ? `${status.name} (${tabCount[`tab-${status.name.toLowerCase()}`]})` : status.name} {...a11yProps(index)} />
                    )}
                </Tabs>
                {props.statusList.map((status, index) =>
                    <TabPanel key={`${status.name}-${index}-panel`} value={value} index={index}>
                        <StatusList  key={`${status.name}-${index}-panel-list`} statusId={status.id} columns={props.columns} listQuery={props.listQuery} tabIndex={status.name} objectName={props.objectName} handleTotalCount={handleTotalCount}/>
                    </TabPanel>
                )}
            </Paper>
        </div>
    );
}

