import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FindShipment from "../shipment/FindShipment";
import {useSnackbar} from "notistack";
import { Redirect } from "react-router-dom";

const ORDER_SENT_MUTATION = gql`
    mutation ($id: Int!){
     updateStockOrder(
       input: {
          id: $id
          patch: {
            statusId: 2
        }
      }
    ){
     stockOrder{
      nodeId
       id
       }
     }
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    paperFullWidth: {
        overflowY: 'visible'
    },
    dialogContentRoot: {
        overflowY: 'visible'
    }
}));

export default function OrderCancel(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [cancelOrder, { data: canceledOrder,  loading: mutationLoading, error: mutationError}] = useMutation(ORDER_SENT_MUTATION);


    React.useEffect(() => {
        (mutationError) && enqueueSnackbar(`Server encountered an error`, {variant: "error"})
    }, [mutationError]);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: mutationLoading,
    });

    const handleSentOrder = () => {
        cancelOrder({
            variables: {
                id : parseInt(props.orderId)
            }
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {!canceledOrder || mutationError ?
        <div>
            <Button
                variant="contained"
                disabled={props.loading}
                size="large"
                color="secondary"
                onClick={handleClickOpen}

            >
                Cancel
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                // maxWidth="md"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paperFullWidth: classes.paperFullWidth
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Cancel Order?"}</DialogTitle>
                <DialogContent  classes={{
                    root: classes.dialogContentRoot
                }}>
                    <DialogContentText id="alert-dialog-description">
                        Order has {props.records? props.records.reduce((total, record) => record.qty + total, 0): 0} blinds
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        className={buttonClassname}
                        disabled={mutationLoading}
                        onClick={handleSentOrder}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
            :<Redirect to={`/orders`} />}
        </React.Fragment>
    );
}