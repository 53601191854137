import {warehouseQueueInstance} from './axios';

export const warehouseQueue = (params) => new Promise((resolve, reject) => {
    warehouseQueueInstance.post('/warehouse-app', {
        params
    }).then(async response => {
        console.log(response);
        resolve(response)
    }).catch(error => {
        reject(error);
    });
});

