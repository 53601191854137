import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import Edit from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/moment';
import Paper from '@material-ui/core/Paper';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {gql} from "@apollo/client/core";
import {useMutation} from "@apollo/client";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    summaryContainer:{
        marginTop: theme.spacing(2),
    },
    summaryTable: {
    },
    summaryTableRow: {
    },
    summaryTableThCell:{
        width: 250
    },
    summaryTableCell:{
    },
    summaryTableCell1:{
        paddingTop:  theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}));


const UPDATE_SHIPMENT_MUTATION = gql`
    mutation ($id: Int!, $shipment: ShipmentPatch!){
     updateShipment(
       input: {
          id: $id
          patch: $shipment
      }
    ){
    shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
           }
          }
        }
    }
    }
`;

export default function ShipmentActions(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [shipmentValues, setShipmentValues] = React.useState(props.shipment);
    const [updateShipment, { data: updatedShipment, loading: mutationLoading, error: mutationError}] = useMutation(UPDATE_SHIPMENT_MUTATION);

    React.useEffect(() => {
        mutationError && enqueueSnackbar(`Server encountered an error: ${mutationError.message}`, {variant: "error"})
    }, [mutationError]);


    return (
            <React.Fragment>
            <Card className={classes.root}>
                <CardContent>
                    asd
                </CardContent>
            </Card>
            </React.Fragment>
    )
}