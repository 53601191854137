import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SkuAddForm from "./SkuAddForm";
import {Link as RouterLink} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledMenuItem = withStyles((theme) => ({
    root: {
    },
}))(MenuItem);

export default function SkuAddFormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (even, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpen(false);
        }
    };

    const handleLoading = (value) => {
        setLoading(value);
    };
    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>Add SKU</Button>
            <Dialog
                open={open}
                scroll="body"
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {
                        !loading ?
                            'Add SKU'
                        : <CircularProgress color="primary" size={50} />
                    }
                </DialogTitle>
                <DialogContent>
                    <SkuAddForm formType="Add" loading={loading} handleLoading={handleLoading} handleClose={handleClose} handleReFetch={props.handleReFetch}/>
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button onClick={handleClose} color="primary">*/}
                {/*        Cancel*/}
                {/*    </Button>*/}
                {/*    <Button onClick={handleSubmit} color="primary" variant="contained">*/}
                {/*        Add*/}
                {/*    </Button>*/}
                {/*</DialogActions>*/}
            </Dialog>
        </div>
    );
}
