import * as React from 'react';
import {
    GridOverlay,
    DataGrid,
    GridToolbarContainer,
    GridColumnsToolbarButton,
    GridFilterToolbarButton,
    GridToolbarExport,
    GridDensitySelector
} from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {gql} from "@apollo/client/core";
import {useLazyQuery, useMutation} from "@apollo/client";
import {makeStyles} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {getCurrentDateTime} from "../../utils/DateFormatMoment";
import useDeepCompareEffect from 'use-deep-compare-effect';


function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1)`,
            color: '#750f0f',
        },
    }
}));

const GET_STOCK_QUERY = gql`
query StockedBlinds($offset: Int!, $limit: Int!, $filter: StockedBlindFilter) {
  stockedBlinds(first: $limit, offset: $offset, orderBy: [COLOUR_BY_COLOUR_ID__NAME_ASC, COLOUR_GROUP_BY_COLOUR_GROUP_ID__NAME_ASC, WIDTH_ASC],filter:$filter){
    totalCount
    pageInfo {
      hasNextPage
    }
    nodes{
      id
      width
      depth
      shelfQuantity
      uncommittedQuantity
      inTransit
      xeroInventoryCode
      discontinued
      colour{
        name
      }
      colourGroup{
        name
      }
      supplier{
        name
      }
      baseBarColour{
        name
      }
    }
  }
}
`;


const UPDATE_STOCKED_BLIND_MUTATION = gql`
    mutation ($input: UpdateStockedBlindInput!){
     updateStockedBlind(
       input: $input
    ){
     stockedBlind{
      nodeId
      id
      width
      depth
      shelfQuantity
      uncommittedQuantity
      inTransit
      discontinued
      updatedAt
      xeroInventoryCode
      colour{
        name
      }
      colourGroup{
        name
      }
      supplier{
        name
      }
      baseBarColour{
        name
      }
   }
   }
 }
`;


export default function SkuList(propsParent) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [filters, setFilters] = React.useState([{
        id: {greaterThan: 0}
    }]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [ getList, { called, loading, data, error } ] = useLazyQuery(GET_STOCK_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            offset: 0,
            limit: 100,
            filter: {
                and: filters
            }
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setRows(data.stockedBlinds.nodes);
            setTotalCount(data.stockedBlinds.totalCount);
        }
    });

    const [updateStockBlind, { data: updatedStockBlind, loading: mutationLoading, error: mutationError}] = useMutation(UPDATE_STOCKED_BLIND_MUTATION, {
        onCompleted: data1 => {
            enqueueSnackbar('Saved', {variant: "success"});
        }
    });
    useDeepCompareEffect(() => {
        getList({
            notifyOnNetworkStatusChange: true,
            variables: {
                offset: 0,
                limit: 100,
                filter: {
                    and: filters
                }
            }
        })
    }, [filters]);


    React.useEffect(() => {
        getList({
            notifyOnNetworkStatusChange: true,
            variables: {
                offset: page*100,
                limit: 100
            }
        })
    }, [page]);

    React.useEffect(() => {
        if(propsParent.reFetch) {
            getList({
                notifyOnNetworkStatusChange: true,
                variables: {
                    offset: page * 100,
                    limit: 100
                }
            });
            propsParent.handleReFetch(false);
        }
    }, [propsParent.reFetch]);


    React.useEffect(() => {
        (error || mutationError) && enqueueSnackbar(`Server encountered an error`, {variant: "error"})
    }, [error, mutationError]);

    // const [filterModel, setFilterModel] = React.useState({ items: [],});
    const [editRowsModel, setEditRowsModel] = React.useState({});

    const handleEditCellChangeCommitted = React.useCallback(
        async ({ id, field, props }) => {
            if (field === 'shelfQuantity' || field === 'uncommittedQuantity' || field === 'inTransit') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const qty = parseInt(data.value);
                const updatedRows = rows.map( (row) => {
                    if (row.id === id && row[field] !== qty) {
                        updateStockBlind({variables: {
                            input: {
                                id: id,
                                patch: {
                                    [field]: qty,
                                    updatedAt: getCurrentDateTime()
                                }
                            }
                        }});
                        return { ...row, [field]: qty };
                    }
                    return row;
                });
                setRows(updatedRows);
            }
            if (field === 'xeroInventoryCode') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const value = data.value;
                const updatedRows = rows.map( (row) => {
                    if (row.id === id && row[field] !== value) {
                        updateStockBlind({variables: {
                                input: {
                                    id: id,
                                    patch: {
                                        [field]: value,
                                        updatedAt: getCurrentDateTime()
                                    }
                                }
                            }});
                        return { ...row, [field]: value };
                    }
                    return row;
                });
                setRows(updatedRows);
            }
            if (field === 'discontinued') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const val = data.value;
                const updatedRows = rows.map( (row) => {
                    if (row.id === id && row[field] !== val) {
                        updateStockBlind({variables: {
                                input: {
                                    id: id,
                                    patch: {
                                        [field]: val
                                    }
                                }
                            }});
                        return { ...row, [field]: val };
                    }
                    return row;
                });
                setRows(updatedRows);
            }

        },
        [rows],
    );
    const handleEditCellChange = React.useCallback(
        ({ id, field, props }) => {
            if (field === 'shelfQuantity' || field === 'uncommittedQuantity' || field === 'inTransit') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const qty = parseInt(data.value);
                const isValid = qty >= 0;
                const newState = {};
                newState[id] = {
                    ...editRowsModel[id],
                    [field]: {...props, error: !isValid},
                };
                setEditRowsModel((state) => ({...state, ...newState}));
            }
        }, [editRowsModel],
    );



    const columns = [
        { field: 'colourGroup', headerName: 'Fabric', flex: 1,editable: false,  valueGetter: ({ value }) => value.name },
        { field: 'colour', headerName: 'Colour', flex: 1,editable: false,  valueGetter: ({ value }) => value.name },
        { field: 'width', headerName: 'Width',  flex: 1, type: 'number', editable: false },
        { field: 'depth', headerName: 'Drop',  flex: 1, type: 'number', editable: false },
        { field: 'shelfQuantity', headerName: 'In Stock', flex: 1, type: 'number', editable: true },
        { field: 'uncommittedQuantity', headerName: 'Available Stock', flex: 1, type: 'number', editable: true },
        { field: 'inTransit', headerName: 'In Transit', flex: 1, type: 'number', editable: true },
        { field: 'xeroInventoryCode', headerName: 'Xero Inventory Code', flex: 1, type: 'string', editable: true },
        { field: 'discontinued', headerName: 'Discontinued', flex: 1, type: 'boolean', editable: true },
    ];


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridColumnsToolbarButton />
                <GridFilterToolbarButton />
                <GridDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    const filterParamMap = {
        contains: 'likeInsensitive',
        equals: 'like',
        startsWith: 'startsWith',
        endsWith: 'endsWith',
        '>' : 'greaterThan',
        '>=' : 'greaterThanOrEqualTo',
        '<' : 'lessThan',
        '<=' : 'lessThanOrEqualTo',
        '=' : 'equalTo',
        '!=': 'notEqualTo',
        'is': 'equalTo',
    };
    const onFilterChange = React.useCallback((params) => {
        let f = [];
        for(let item of params.filterModel.items){
            if(item.value) {
                let filter = {};
                if ([ 'contains', 'equals', 'startsWith', 'endsWith'].includes(item.operatorValue)) {
                    filter = {
                        [item.columnField]: {
                            name: {
                                [filterParamMap[item.operatorValue]]: item.operatorValue === 'contains'? `%${item.value}%`: item.value
                            }
                        }
                    };
                    f.push(filter)
                }
                else if (item.operatorValue === 'is') {
                    filter = {
                        [item.columnField]: {
                                [filterParamMap[item.operatorValue]]: item.value === 'true'
                        }
                    };
                    f.push(filter)
                }
                else {
                    filter = {
                        [item.columnField]: {
                                [filterParamMap[item.operatorValue]]: parseInt(item.value)
                            }
                        }
                    }
                    f.push(filter)
                }
        }
        if(f.length > 0){
            let filter = [...filters, ...f];
            setFilters(filter);
        }
        else {
            setFilters([{
                id: {greaterThan: 0}
            }])
        }

    }, []);

    return (
        <React.Fragment>
            <div className={classes.root} style={{ height: '85vh', width: '100%', display: 'flex'}}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        title={`SKU`}
                        className={classes.root}
                        rows={rows}
                        columns={columns}
                        pageSize={100}
                        rowCount={totalCount}
                        onEditCellChange={handleEditCellChange}
                        onEditCellChangeCommitted={handleEditCellChangeCommitted}
                        // loading={loading}
                        density="compact"
                        editRowsModel={editRowsModel}
                        // filterModel={filterModel}
                        onFilterModelChange={onFilterChange}
                        loading={loading||mutationLoading}
                        paginationMode="server"
                        filterMode="server"
                        onPageChange={handlePageChange}
                        components={{
                            Toolbar: CustomToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                        }}


                    />
                </div>
            </div>
        </React.Fragment>
    );
}


