import React from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import Button from '@material-ui/core/Button';
import Moment from "react-moment";
import 'moment-timezone';
import {getTableRowId} from "../../utils/muiDatatablesUtils";
import {gql} from "@apollo/client/core";
import StatusTabList from "../../components/StatusTabList";
import OrderListActions from "../../components/orders/OrderListActions";
import ShipmentListActions from "../../components/shipment/ShipmentListActions";
import StatusList from "../../components/StatusList";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

const GET_SHIPMENTS_QUERY = gql`
query($offset: Int!, $limit: Int!){
   shipments(offset:$offset, first:$limit, orderBy: [CREATED_AT_DESC]){
    pageInfo {
      hasNextPage
    }
    totalCount
    nodes{
      id
      status{
        name
      }
      name
      consignmentNumber
      invoiceNumber
      createdAt
      updatedAt
      stockOrderLines{
        totalCount
        nodes{
           qty
           order{
              sentAt
           }
        }
      }
    }
  }
}
`;

const statusColorMap = {
    'Shipped': '#f49454',
    'Requested': '#2979ff',
    'Received': '#4caf50'
};

export const statusDisplayMap = {
    'Shipped' : 'Shipped',
    'Requested' : 'Requested',
    'Received' : 'Received'
};

export default function ShipmentView(props) {
    const classes = useStyles();
    const getOrderdate = (tableMeta) => {
        if(tableMeta.tableData.length > 0 && tableMeta.tableData[tableMeta.rowIndex].stockOrderLines.nodes.length > 0){
            return <Typography> Ordered On <Moment format="DD/MM/YYYY">{tableMeta.tableData[tableMeta.rowIndex].stockOrderLines.nodes[0].order.sentAt}</Moment></Typography>;
        }
    }
    const statusList = [
        {
            name: 'Requested',
            id: 1
        },
        {
            name: 'Shipped',
            id: 2
        },
        {
            name: 'Received',
            id: 3
        }
    ];
    const [columns, setColumns] = React.useState([
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "status.name",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value && <Typography style={{color: statusColorMap[value.trim()]}}>{statusDisplayMap[value.trim()]}</Typography>
                )
            }
        },
        {
            name: "stockOrderLines.nodes",
            label: "Details",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value && <Typography>{value.reduce(
                        (accumulator, currentValue) => accumulator + currentValue.qty
                        , 0
                    )} blinds{getOrderdate(tableMeta)}</Typography>
                )
            }
        },
        // {
        //     name: "name",
        //     label: "Name",
        //     options: {
        //         filter: false,
        //         sort: false
        //     }
        // },
        {
            name: "consignmentNumber",
            label: "Consignment Number",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "invoiceNumber",
            label: "Invoice Number",
            options: {
                filter: false,
                sort: false
            }
        },

        {
            name: "updatedAt",
            label: "Updated",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value && <Moment fromNow local>{value}</Moment>
                )
            }
        }

    ]);
    return (
        <DocumentTitle title="List | Shipments">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <div className={classes.action}>
                        <Button variant="contained" color="primary" component={RouterLink} to="/orders">Orders</Button>
                    </div>
                    <StatusList  statusList={statusList} columns={columns} listQuery={GET_SHIPMENTS_QUERY} objectName="shipments" detailUrl='/shipments'/>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

