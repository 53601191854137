import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";
const CREATE_ORDER_MUTATION = gql`
mutation ($stockOrderLines: [FkStockOrderLineStockOrderStockOrderLinesCreateInput!]){
createStockOrder(
    input: {
      stockOrder: {
        statusId: 1
        stockOrderLinesUsingId: {
          create: $stockOrderLines
        }
      }
    }
) {
  stockOrder {
    id
    status{
      name
    }
    stockOrderLinesByOrderId {
      nodes {
        id
        fabric
        colour
        width
        depth
      }
    }
  }
}
}
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function OrderCreate(props) {
    const classes = useStyles();
    let history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [btnDisabled, setButtonDisabled] = React.useState(false);
    const [createOrder, { data: createdOrder,  loading: mutationLoading, error: mutationError}] = useMutation(CREATE_ORDER_MUTATION, {
        onCompleted: ({createStockOrder: { stockOrder } }) => {
            enqueueSnackbar('Order Created', {variant: "success"});
            history.push(`/orders/${stockOrder.id}`)
        }
    });
    const buttonClassname = clsx({
        [classes.buttonSuccess]: mutationLoading,
    });

    React.useEffect(() => {
        mutationError && enqueueSnackbar(`Server encountered an error: ${mutationError.message}`, {variant: "error"})
    }, [mutationError]);
    const handleCreateOrder = () => {
        const records = props.records.filter(record => record.qtyToReorder > 0);
        const stockOrderLines = records.map(({
                                                 id: stockedBlindId,
                                                 qtyToReorder: qty,
                                                 inTransit,
                                                 shelfQuantity,
                                                 weeklyAve,
                                                 __typename,
                                                 ...rest
                                             }) => ({
            stockedBlindId,
            qty,
            ...rest
        }));
        createOrder({
            variables: {
                stockOrderLines : stockOrderLines
            }
        })

    };

    React.useEffect(() => {
        if(props.records){
            let total = props.records.reduce((total, record) => record.qtyToReorder + total, 0);
            setButtonDisabled(total === 0);
        }
    }, [props.records]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
        <div>
            <Button
                variant="contained"
                disabled={props.loading || btnDisabled}
                size="large"
                color="primary"
                onClick={handleCreateOrder}
            >
                Continue
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Order Creation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Order will be created for total {props.records? props.records.reduce((total, record) => record.qtyToReorder + total, 0): 0} blinds
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={mutationLoading}
                        onClick={handleCreateOrder}
                        autoFocus
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        </React.Fragment>
    );
}