import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import ShipmentSummaryDetails from "./ShipmentSummaryDetails";
import ShipmentActions from "./ShipmentActions";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ShipmentStatusUpdate from "./ShipmentStatusUpdate";
const useStyles = makeStyles(theme => ({

}));



export default function ShipmentDetails(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return (
        <React.Fragment>
            <ShipmentSummaryDetails {...props}/>
        </React.Fragment>
    )
}