import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Typography } from "@material-ui/core";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {loginRequest} from "../authConfig";

const ErrorComponent = ({error}) => {
    return <Typography variant="caption">An Error Occurred: {error.errorCode}</Typography>;
};

const Loading = () => {
    return <Typography variant="caption">Authentication in progress...</Typography>
};

export default function PrivateRoute({ component: Component, ...rest }){
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <Route {...rest} render={() => <Component {...rest} />}/>
        </MsalAuthenticationTemplate>
    );
};