import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function OrderCsv(props) {
    const classes = useStyles();
    const headers = [
        { label: "Fabric", key: "fabric" },
        { label: "Colour", key: "colour" },
        { label: "Width", key: "width" },
        { label: "Drop", key: "depth" },
        { label: "Qty To Reorder", key: "qty" },
        { label: "Base Bar", key: "baseBar" },
        { label: "Bracket", key: "bracket" },
        { label: "Chain Length", key: "chainLength" },
        { label: "Roll Direction", key: "rollDirection" },
        { label: "Control Side", key: "controlSide" },
        { label: "Sticker", key: "sticker" },
        { label: "Notes", key: "notes" },
    ];
    return (
        <CSVLink
            data={props.records}
            headers={headers}
            filename={"order.csv"}
        >
            Download Order
        </CSVLink>
    )
}
