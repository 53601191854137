import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import OrderVariableEditForm from "./OrderVariableEditForm";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: "1rem",
        minWidth: "275"
    },

    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

export default function OrderVariableCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={3} >
            <CardContent>
                <Typography variant="h5" component="h1">
                    {" "}
                    <strong>Variables</strong>
                </Typography>
                <Typography variant="subtitle2">
                    <hr />
                </Typography>
                <OrderVariableEditForm {...props}/>
            </CardContent>
        </Card>
    );
}
