import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import {
    Autocomplete
} from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {gql} from "@apollo/client/core";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {useSnackbar} from "notistack";
import {getCurrentDateTime} from "../../utils/DateFormatMoment";

const ADD_SKU_MUTATION = gql`
    mutation ($input: CreateStockedBlindInput!){
     createStockedBlind (
       input: $input
     )
     {
     stockedBlind{
      nodeId
      id
      width
      depth
      shelfQuantity
      uncommittedQuantity
      inTransit
      discontinued
      updatedAt
      colour{
        name
      }
      colourGroup{
        name
      }
      supplier{
        name
      }
      baseBarColour{
        name
      }
      replacedBy
   }
   }
    }
`;


const GET_FABRICS_QUERY = gql`
query colourGroups{
  colourGroups(orderBy: [NAME_ASC]){
    totalCount
    nodes{
      id
      name
    }
  }
}
`;

const GET_STOCKED_BLIND_QUERY = gql`
query stockedBlinds{
  stockedBlinds(orderBy: [COLOUR_BY_COLOUR_ID__NAME_ASC, COLOUR_GROUP_BY_COLOUR_GROUP_ID__NAME_ASC, WIDTH_ASC]){
    totalCount
    nodes{
      id
      width
      depth
      colour{
        name
      }
      colourGroup{
        name
      }
    }
  }
}
`;

const GET_COLOURS_QUERY = gql`
query colours{
  colours(orderBy: [NAME_ASC]){
    nodes{
      id
      name
    }
  }
}
`;

const GET_SUPPLIER_QUERY = gql`
query providers{
  providers(orderBy: [NAME_ASC]){
    nodes{
      id
      name
    }
  }
}
`;

const GET_BAY_QUERY = gql`
query bays{
  bays(orderBy: [NUMBER_ASC]){
    nodes{
      id
      number
    }
  }
}
`;


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '500px',
        },
    },
    submitButton: {
        margin: theme.spacing(1),
        float: 'right'
    }
}));


const Schema = Yup.object().shape({
    colourGroup: Yup.object().required('Required'),
    colour: Yup.object().required('Required'),
    supplier: Yup.object().required('Required'),
    baseBarColour: Yup.object().required('Required'),
    bay: Yup.object(),
    width: Yup.number().positive().required('Required'),
    depth: Yup.number().positive().required('Required'),
    shelfQuantity: Yup.number().min(0).required('Required'),
    uncommittedQuantity: Yup.number().min(0).required('Required'),
    inTransit: Yup.number().min(0).required('Required'),
});



export default function SkuAddForm(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [fabrics, setFabrics] = React.useState([]);
    const [stockedBlinds, setStockedBlinds] = React.useState([]);
    const [colours, setColours] = React.useState([]);
    const [suppliers, setSuppliers] = React.useState([]);
    const [bays, setBays] = React.useState([]);

    const [addSku, {  loading: mutationLoading, error: mutationError }] = useMutation(ADD_SKU_MUTATION, {
        onCompleted: ({ createStockedBlind: { stockedBlind } }) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
        }
    });
    const [ getStockedBlindList ] = useLazyQuery(GET_STOCKED_BLIND_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {},
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setStockedBlinds(data.stockedBlinds.nodes);
        }
    });
    const [ getFabricList ] = useLazyQuery(GET_FABRICS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {},
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setFabrics(data.colourGroups.nodes);
        }
    });
    const [ getColourList ] = useLazyQuery(GET_COLOURS_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {},
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setColours(data.colours.nodes);
        }
    });
    const [ getSupplierList ] = useLazyQuery(GET_SUPPLIER_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {},
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setSuppliers(data.providers.nodes);
        }
    });
    const [ getBayList ] = useLazyQuery(GET_BAY_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {},
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            setBays(data.bays.nodes);
        }
    });
    React.useEffect(() => {
        mutationError && enqueueSnackbar(`Server encountered an error: ${mutationError.message}`, {variant: "error"})
    }, [mutationError]);

    React.useEffect(() => {
        getStockedBlindList();
        getFabricList();
        getColourList();
        getSupplierList();
        getBayList();
    }, []);
    React.useEffect(() => {
        if(stockedBlinds.length > 0 && fabrics.length > 0 && colours.length > 0 && suppliers.length > 0 && bays.length > 0){
            props.handleLoading(false)
        }
    }, [stockedBlinds, fabrics, colours, suppliers, bays]);
    return (
        <React.Fragment>
            {!props.loading?
                <Formik
                    initialValues={{
                        replacedBy: null,
                        colourGroup: {
                            id: 647,
                            name: 'Dusk'
                        },
                        supplier: {
                            id: 39,
                            name: 'Blaze'
                        },
                        baseBarColour: {
                            id: 159,
                            name: 'Anodized'
                        },
                        shelfQuantity: 0,
                        uncommittedQuantity: 0,
                        inTransit: 0,
                        colour: "",
                        width: "",
                        depth: "",
                        bay: "",
                    }}
                    validationSchema={Schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                            addSku({
                                variables: {
                                    input: {
                                        stockedBlind: {
                                            colourGroupId: values.colourGroup.id,
                                            colourId: values.colour.id,
                                            baseBarColourId: values.baseBarColour.id,
                                            supplierId: values.supplier.id,
                                            bayId: values.bay.id,
                                            width : values.width,
                                            depth : values.depth,
                                            shelfQuantity: values.shelfQuantity,
                                            uncommittedQuantity: values.uncommittedQuantity,
                                            inTransit: values.uncommittedQuantity,
                                            xeroInventoryCode: `${values.colourGroup.name.substring(0, 3)}${values.colour.name.substring(0, 3)}${values.width}${values.depth}`.toUpperCase(),
                                            replacedBy: values.replacedBy ? values.replacedBy.id : null,
                                            updatedAt: getCurrentDateTime(),
                                            createdAt: getCurrentDateTime(),
                                    }
                                  }
                                }
                            });
                            props.handleClose();
                            props.handleReFetch(true);
                        }, 500);
                    }}
                >
                    {({ handleChange, errors, handleSubmit, isSubmitting, touched }) => (
                        <Form className={classes.root} autoComplete="off">
                            {/*{errors.name ? (*/}
                            {/*    <div>{errors}</div>*/}
                            {/*) : null}*/}
                            {/*<div>{JSON.stringify(errors)}</div>*/}
                            <div>
                                <Field
                                    name="replacedBy"
                                    component={Autocomplete}
                                    options={stockedBlinds}
                                    getOptionLabel={(option) => `${option.colourGroup.name} ${option.colour.name} ${option.width}X${option.depth}`}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['colourGroup'] && !!errors['colourGroup']}
                                            helperText={touched['colourGroup'] && errors['colourGroup']}
                                            label="Replacing"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Field
                                    name="colourGroup"
                                    component={Autocomplete}
                                    options={fabrics}
                                    getOptionLabel={(option) => option.name}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['colourGroup'] && !!errors['colourGroup']}
                                            helperText={touched['colourGroup'] && errors['colourGroup']}
                                            label="Fabric"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Field
                                    name="colour"
                                    component={Autocomplete}
                                    options={colours}
                                    getOptionLabel={(option) => option.name}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['colour'] && !!errors['colour']}
                                            helperText={touched['colour'] && errors['colour']}
                                            label="Colour"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    name="width"
                                    type="number"
                                    label="Width"
                                    variant="outlined"
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    name="depth"
                                    type="number"
                                    label="Drop"
                                    variant="outlined"
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    name="supplier"
                                    component={Autocomplete}
                                    options={suppliers}
                                    getOptionLabel={(option) => option.name}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['supplier'] && !!errors['supplier']}
                                            helperText={touched['supplier'] && errors['supplier']}
                                            label="Supplier"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Field
                                    name="baseBarColour"
                                    component={Autocomplete}
                                    options={colours}
                                    getOptionLabel={(option) => option.name}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['baseBarColour'] && !!errors['baseBarColour']}
                                            helperText={touched['baseBarColour'] && errors['baseBarColour']}
                                            label="Base Bar Colour"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    name="shelfQuantity"
                                    type="number"
                                    label="In Stock"
                                    variant="outlined"
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    name="uncommittedQuantity"
                                    type="number"
                                    label="Available Quantity"
                                    variant="outlined"
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    component={TextField}
                                    name="inTransit"
                                    type="number"
                                    label="In Transit"
                                    variant="outlined"
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    name="bay"
                                    component={Autocomplete}
                                    options={bays}
                                    getOptionLabel={(option) => option.number}
                                    style={{width: 300}}
                                    renderInput={(params) => (
                                        <MuiTextField
                                            {...params}
                                            error={touched['bay'] && !!errors['bay']}
                                            helperText={touched['bay'] && errors['bay']}
                                            label="Bay"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.submitButton}>
                                <Button onClick={props.handleClose} disabled={mutationLoading} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit}
                                >
                                   Add
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                : null}
        </React.Fragment>
    );
}