import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import { IntlProvider} from 'react-intl';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Error404 from "./views/Error404";
import StockView from "./views/StockView";
import OrderView from "./views/orders/OrderView";
import OrderCreateView from "./views/orders/OrderCreateView";
import DashboardView from "./views/DashboardView";
import NavBar from "./components/NavBar";
import DocumentTitle from 'react-document-title';
import OrderDetailView from "./views/orders/OrderDetailView";
import ShipmentDetailView from "./views/shipments/ShipmentDetailView";
import indigo from '@material-ui/core/colors/indigo';
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import LoginView from "./views/auth/LoginView";
import PrivateRoute from "./components/PrivateRoute";
import ShipmentView from "./views/shipments/ShipmentView";
import {EventType} from "@azure/msal-browser";
import {msalInstance} from "./index";
import {IntlProvider} from "react-intl";


const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#141414',
      },
      secondary: {
          main: '#dc0000',
    },
    }
});

function App(props) {
    const [isNavbarHidden, setIsNavbarHidden] = React.useState(true);
    React.useEffect(() => {
        if(props.pca.getActiveAccount() !== null){
            setIsNavbarHidden(false)
        }
    });
    React.useEffect(() => {
        const callbackId = props.pca.addEventCallback((message) => {
            if (message.eventType === EventType.LOGIN_SUCCESS && message.payload.account) {
                setIsNavbarHidden(false)
            }
        });
    });
    function handleNavbar(isNavbarHidden) {
        setIsNavbarHidden(isNavbarHidden);
    }
  return (
      <MsalProvider instance={props.pca}>
          <DocumentTitle title="Warehouse">
          <React.Fragment>
            <IntlProvider locale="en">
              <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}>
                  <div>
                    <Router>
                      {!isNavbarHidden? <NavBar />: null}
                      <Switch>
                          <PrivateRoute exact path="/shipments/:id" component={ShipmentDetailView}/>
                          <PrivateRoute exact path="/shipments" component={ShipmentView}/>
                          <PrivateRoute exact path="/orders/create" component={OrderCreateView}/>
                          <PrivateRoute exact path="/orders/:id" component={OrderDetailView}/>
                          <PrivateRoute exact path="/orders" component={OrderView}/>
                          <PrivateRoute exact path="/sku" component={StockView}/>
                          <Route exact path="/login" render={() => <LoginView handleNavbar={handleNavbar}/>}/>
                          <PrivateRoute exact path="/" component={DashboardView}/>
                          <Route path="*" render={() => <Error404 errorName={'404 ERROR'}/>}  />
                      </Switch>
                    </Router>
                  </div>
                </SnackbarProvider>
              </ThemeProvider>
            </IntlProvider>
          </React.Fragment>
          </DocumentTitle>
      </MsalProvider>
  );
}

export default App;
