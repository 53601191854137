import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { offsetLimitPagination } from "@apollo/client/utilities";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});


const link = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI
});

const authLink = setContext((_, { headers }) => {
    let customHeaders = headers;
    if (process.env.REACT_APP_GRAPHQL_HEADERS !== ''){
        customHeaders = {
            ...customHeaders,
            'x-api-key': process.env.REACT_APP_GRAPHQL_HEADERS,
        }
    }
    return {
        headers: {
            ...customHeaders,
        }
    }
});

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                feed: offsetLimitPagination()
            },
        },
    },
});

const client = new ApolloClient({
    cache: cache,
    link: authLink.concat(link)
});



ReactDOM.render(
  <React.Fragment>
      <ApolloProvider client={client}><App pca={msalInstance} /></ApolloProvider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
