import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { BrowserRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(1, 2),
    },
    link: {
        textDecoration: "none",
        color: "rgba(0, 0, 0, 0.87)",
    }
}));


const routes = [
    { path: '/', breadcrumb: 'Dashboard'  },
    { path: '/jobs', breadcrumb: 'Jobs' },
    { path: '/report', breadcrumb: 'Report' },
    { path: '/reconcile', breadcrumb: 'Reconcile' },
];

// map & render your breadcrumb components however you want.
function Breadcrumb(props){
    //console.log(props);
    const classes = useStyles();
    const [breadcrumbs, setBreadCrumbs] = React.useState(props.breadcrumbs);

    React.useEffect(() => {
        setBreadCrumbs(props.breadcrumbs);
    }, [props.breadcrumbs]);
    return(
        <div className={classes.root}>
            <Paper elevation={2} className={classes.paper}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs.map(({
                                          match,
                                          breadcrumb
                                          // other props are available during render, such as `location`
                                          // and any props found in your route objects will be passed through too
                                      }) => (
                        <Link key={breadcrumb} color="inherit" to={match.url} className={classes.link}>
                            {breadcrumb}
                        </Link>
                    ))}
                </Breadcrumbs>
            </Paper>
        </div>
    )
}

export default withBreadcrumbs(routes)(Breadcrumb);