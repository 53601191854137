import * as React from 'react';
import {
    GridOverlay,
    DataGrid,
    GridToolbarContainer,
    GridColumnsToolbarButton,
    GridFilterToolbarButton,
    GridToolbarExport,
    GridDensitySelector
} from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {gql} from "@apollo/client/core";
import {useMutation, useQuery} from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {useSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from '@material-ui/core/Checkbox';


function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1)`,
            color: '#750f0f',
        },
    }
}));


const UPDATE_STOCK_ORDER_LINE_QUERY = gql`
    mutation ($id: Int!, $qty: Int!){
     updateStockOrderLine(
       input: {
          id: $id
          patch: {
            qty: $qty
        }
      }
    ){
     stockOrderLine{
      nodeId
          id
          fabric
          colour
          width
          depth
          qty
          baseBar
          bracket
          chainLength
          rollDirection
          controlSide
          sticker
          received
          stockedBlind{
            id
            xeroInventoryCode
            stockedBlindPrices {
            nodes{
                amount
              }
            }
          }
       }
     }
    }
`;


export default function ShipmentLinesTable(propsParent) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // React.useEffect(() => {
    //     error && enqueueSnackbar(`Server encountered an error: ${error.message}`, {variant: "error"})
    // }, [error]);
    const [filterModel, setFilterModel] = React.useState({ items: [],});
    const [editRowsModel, setEditRowsModel] = React.useState({});

    const handleEditCellChangeCommitted = React.useCallback(
        async ({ id, field, props }) => {
            if (field === 'shipmentQty') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const shipmentQty = parseInt(data.value);
                const updatedRows = propsParent.stockOrderLines.map( (row) => {
                    if (row.id === id) {
                        return { ...row, shipmentQty:shipmentQty };
                    }
                    return row;
                });
                propsParent.handleUpdateStockOrderLines(updatedRows);
            }
            if (field === 'received') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const val = data.value;
                const updatedRows = propsParent.stockOrderLines.map( (row) => {
                    if (row.id === id && row[field] !== val) {
                        return { ...row, [field]: val };
                    }
                    return row;
                });
                propsParent.handleUpdateStockOrderLines(updatedRows);
            }

        },
        [propsParent.stockOrderLines],
    );
    const handleEditCellChange = React.useCallback(
        ({ id, field, props }) => {
            if (field === 'shipmentQty') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const shipmentQty = parseInt(data.value);
                const qty = propsParent.stockOrderLines.find(row => row.id === id).qty;
                const isValid = ((shipmentQty <= qty) && (shipmentQty >= 0)) || !shipmentQty;
                const newState = {};
                newState[id] = {
                    ...editRowsModel[id],
                    shipmentQty: {...props, error: !isValid},
                };
                setEditRowsModel((state) => ({...state, ...newState}));
            }
        }, [editRowsModel],
    );

    const handleConfirmRow = (params) => {
        const val = params.value;
        const updatedRows = propsParent.stockOrderLines.map( (row) => {
            if (row.id === params.id) {
                return { ...row, [params.field]: !val };
            }
            return row;
        });
        propsParent.handleUpdateStockOrderLines(updatedRows);
    };

    const columns = [
        { field: 'colour', headerName: 'Colour', flex: 1,editable: false },
        { field: 'width', headerName: 'Width',  flex: 1, type: 'number', editable: false },
        { field: 'depth', headerName: 'Drop',  flex: 1, type: 'number', editable: false },
        { field: 'qty', headerName: propsParent.shipment.status.name === 'Shipped'? 'Qty Shipped': 'Qty Ordered', flex: 1, type: 'number', editable: false },
        { field: 'shipmentQty', headerName: propsParent.shipment.status.name === 'Shipped'? 'Qty Received': 'Qty Shipped', flex: 1, type: 'number', editable: propsParent.shipment.status.name !== 'Received' },
        {
            field: 'received',
            headerName: 'Confirm',
            flex: 1, type: 'boolean',
            editable: false,
            hidden: true,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    disableRipple={false}
                    onChange={() => handleConfirmRow(params)}
                    name="checkedB"
                    color="primary"
                />
            ),
        },
    ];


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridColumnsToolbarButton />
                <GridFilterToolbarButton />
                <GridDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }
    return (
            <React.Fragment>
                <div className={classes.root} style={{ height: '85vh', width: '100%', display: 'flex'}}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            title={`SKU`}
                            className={classes.root}
                            rows={propsParent.stockOrderLines}
                            columns={columns}
                            pageSize={100}
                            rowCount={propsParent.stockOrderLinesCount}
                            onEditCellChange={handleEditCellChange}
                            onEditCellChangeCommitted={handleEditCellChangeCommitted}
                            // loading={loading}
                            density="compact"
                            editRowsModel={editRowsModel}
                            filterModel={filterModel}
                            components={{
                                Toolbar: CustomToolbar,
                                LoadingOverlay: CustomLoadingOverlay,
                            }}


                        />
                    </div>
                </div>
            </React.Fragment>
    );
}


