import * as React from 'react';
import {
    GridOverlay,
    DataGrid,
    GridToolbarContainer,
    GridColumnsToolbarButton,
    GridFilterToolbarButton,
    GridToolbarExport,
    GridDensitySelector
} from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {gql} from "@apollo/client/core";
import {useMutation, useQuery} from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import OrderCreateSummary from "./OrderCreateSummary";
import {makeStyles} from "@material-ui/core";
import {useSnackbar} from "notistack";



function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        marginBottom: theme.spacing(2)
    }
}));




const GET_STOCK_ORDER_LINES_QUERY = gql`
query($orderId: Int!){
   stockOrder(id: $orderId){   
        nodeId
        id
        status{
          name
        }
        stockOrderLinesByOrderId{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              shipment{
                id
                consignmentNumber
              }
           }
          }
  }
}
`;



const UPDATE_STOCK_ORDER_LINE_QUERY = gql`
    mutation ($id: Int!, $qty: Int!){
     updateStockOrderLine(
       input: {
          id: $id
          patch: {
            qty: $qty
        }
      }
    ){
     stockOrderLine{
      nodeId
          id
          fabric
          colour
          width
          depth
          qty
          baseBar
          bracket
          chainLength
          rollDirection
          controlSide
          sticker
          shipment{
            id
            consignmentNumber
          }
       }
     }
    }
`;


export default function OrderDetailLinesTable(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { data, loading, error, refetch } = useQuery(GET_STOCK_ORDER_LINES_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            orderId: parseInt(props.orderId)
        }
    });
    React.useEffect(() => {
        error && enqueueSnackbar(`Server encountered an error: ${error.message}`, {variant: "error"})
    }, [error]);
    const [columns, setColumns] = React.useState([
        // { field: 'id', headerName: 'ID', flex: 1, editable: false },
        // { field: 'fabric', headerName: 'Fabric', flex: 1, editable: false },
        { field: 'colour', headerName: 'Colour', flex: 2,editable: false },
        { field: 'width', headerName: 'Width',  flex: 1, type: 'number', editable: false },
        { field: 'depth', headerName: 'Drop',  flex: 1, type: 'number', editable: false },
        { field: 'qty', headerName: 'Qty To Reorder', flex: 1, type: 'number', editable: true },
        // { field: 'baseBar', headerName: 'Base Bar', flex: 1, editable: false },
        // { field: 'bracket', headerName: 'Bracket', flex: 1, editable: false },
        // { field: 'chainLength', headerName: 'Chain Length', flex: 1, editable: false },
        // { field: 'rollDirection', headerName: 'Roll Direction', flex: 1, editable: false },
        // { field: 'controlSide', headerName: 'Control Side', flex: 1, editable: false },
        // { field: 'sticker', headerName: 'Sticker', flex: 1, editable: false }
    ]);
    const [order, setOrder] = React.useState(null);

    const [updateStockOrderLine, { loading: mutationLoading, error: mutationError}] = useMutation(UPDATE_STOCK_ORDER_LINE_QUERY, {
        onCompleted: (data) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
        }
    });
    const [rows, setRows] = React.useState([]);
    const [filterModel, setFilterModel] = React.useState({ items: [],});

    React.useEffect(() => {
        (mutationError ) && enqueueSnackbar(`Server encountered an error: ${mutationError}`, {variant: "error"})
    }, [mutationError]);

    React.useEffect(() => {
        if(data){
            if(data.stockOrder.status.name === 'Received' || data.stockOrder.status.name === 'Sent'){
                let fields = columns.map(function(item){ return item.field });
                let clmns = [];
                for(let c of columns){
                    if(c.field === 'qty' && data.stockOrder.status.name === 'Received'){
                        clmns.push({
                            ...c,
                            editable:false
                        })
                    }
                    else {
                        clmns.push(c)
                    }
                }
                if(fields.indexOf('shipment') === -1){
                    setColumns(
                        clmns.concat([{field: 'shipment', headerName: 'Shipment', flex: 1, editable: false, valueGetter: ({ value }) => value? value.consignmentNumber || value.id : ''}])
                    )
                }
            }
            setOrder(data)
        }
    }, [data]);

    React.useEffect(() => {
        if(order){setRows(order.stockOrder.stockOrderLinesByOrderId.nodes)}
    }, [order]);
    const refetchStockOrder = () => {
      refetch();
    };

    const handleUpdateOrder = (order) => {
        setOrder(order)
    };

    const handleEditCellChangeCommitted = React.useCallback(
        async ({ id, field, props }) => {
            if (field === 'qty') {
                const data = props; // Fix eslint value is missing in prop-types for JS files
                const qty = parseInt(data.value);
                const updatedRows = rows.map( (row) => {
                    if (row.id === id) {
                        updateStockOrderLine({variables: {id: id, qty: qty}});
                        return { ...row, qty:qty };
                    }
                    return row;
                });
                setRows(updatedRows);
            }
        },
        [rows],
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridColumnsToolbarButton />
                <GridFilterToolbarButton />
                <GridDensitySelector />
            </GridToolbarContainer>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7} lg={7}>
                {!error ?
                    <Grid container>
                        <Grid item xs>
                            <div className={classes.root} style={{ height: '85vh', width: '100%', display: 'flex'}}>
                                <div style={{ flexGrow: 1 }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={100}
                                        rowCount={order? order.stockOrder.stockOrderLinesByOrderId.totalCount: 0}
                                        onEditCellChangeCommitted={handleEditCellChangeCommitted}
                                        loading={loading}
                                        density="compact"
                                        filterModel={filterModel}
                                        components={{
                                            Toolbar: CustomToolbar,
                                            LoadingOverlay: CustomLoadingOverlay,
                                        }}

                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                : null}
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5}>
                <div className={classes.card}>
                    <OrderCreateSummary {...props} orderStatus={order? order.stockOrder.status.name: null} records={rows} loading={loading} refetchStockOrder={refetchStockOrder} handleUpdateOrder={handleUpdateOrder}/>
                </div>
            </Grid>
        </Grid>

    );
}


