import React from 'react';
import {DropzoneAreaBase, DropzoneArea} from 'material-ui-dropzone'
import {makeStyles} from "@material-ui/core";
import {useSnackbar} from "notistack";
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import S3Upload from 'react-s3-uploader/s3upload';

const useStyles = makeStyles(theme => ({
    buttonGroupRoot: {
        float: 'right'
    }
}));

export default function FileUploader(props) {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [uploadedFiles, setUploadedFiles] = React.useState([]);

    const handleProgress = (progress, textState, file) => {

    };

    const handleError = (err, file) => {

    };

    const handleFinish = (info, file) => {
        const uploadedFile = Object.assign({
            file,
            fileUrl: info.filename
        }, info);
        props.handleFileUploaded(uploadedFile);
    };

    const uploadOptions = {
        signingUrl: '/s3/sign',
        s3path: '',
        contentDisposition: 'auto',
        server: process.env.REACT_APP_S3_SIGN_ENDPOINT,
        signingUrlHeaders: { 'x-api-key': process.env.REACT_APP_S3_SIGN_API_KEY },
        uploadRequestHeaders:{},
        onFinishS3Put: handleFinish,
        onProgress: handleProgress,
        onError: handleError,

    };
    const handleFileDrop = (fileObj) => {
        const options = {
            files: fileObj,
            ...uploadOptions,
        };
        new S3Upload(options)

    };
    const handleFileAdd = (fileObj) => {
        //console.log('Added Files:', fileObj)
    };
    const handleFileDelete = (fileObj) => {
        //console.log('Removed File:', fileObj)
    };

    return (
        <DropzoneArea
            acceptedFiles={[]}
            maxFileSize={9999999999}
            onAdd={handleFileAdd}
            showPreviews={false}
            showPreviewsInDropzone={false}
            onDelete={handleFileDelete}
            onDrop={handleFileDrop}
        />
    )
}