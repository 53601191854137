import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DirectionsBoat from '@material-ui/icons/DirectionsBoat';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import Moment from "react-moment";
import {statusDisplayMap} from "../../views/shipments/ShipmentView";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#141414',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#141414',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#141414',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#141414',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <LocalShippingIcon/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};


const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <VideoLabelIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps(activeStep) {
    return [
        {name:'Requested', icon: ()=>(<ShoppingCart color='primary' />)},
        {name:'Shipped', icon: ()=>(<DirectionsBoat color={activeStep < 1 ? 'disabled' : 'primary'}/>)},
        {name:'Received', icon: ()=>(<BusinessIcon color={activeStep < 2 ? 'disabled' : 'primary'} />)},
    ]
}

const activeStepMap = {
    'Requested' : 0,
    'Shipped' : 1,
    'Received' : 2,
};
export default function ShipmentStepper(props) {
    const classes = useStyles();
    const steps = getSteps(activeStepMap[props.shipment.status.name]);
    const getDate = (name) => {
        if(name === 'Requested' && props.orderDate){
            return <Moment format="DD/MM/YYYY">{props.orderDate}</Moment>
        }
        if(name === 'Shipped' && props.shipment.shippingDate){
            return <Moment format="DD/MM/YYYY">{props.shipment.shippingDate}</Moment>
        }
        if(name === 'Received' && props.shipment.updatedAt){
            return <Moment format="DD/MM/YYYY">{props.shipment.updatedAt}</Moment>
        }
    };
    return (
        <div className={classes.root}>
            <Stepper style={{backgroundColor: 'transparent'}} alternativeLabel activeStep={activeStepMap[props.shipment.status.name]} connector={<QontoConnector />}>
                {steps.map((step) => (
                    <Step key={step.name}>
                        <StepLabel StepIconComponent={step.icon}>
                            <div>{statusDisplayMap[step.name]}</div>
                            <div>{getDate(step.name)}</div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
