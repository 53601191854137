import MUIDataTable from "mui-datatables";
import React, {useEffect} from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client';
import Moment from 'react-moment';
import { createMuiTheme } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';
import {get} from "enzyme/src/configuration";
import { useHistory } from "react-router-dom";

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export default function StatusList(props) {
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ getList, { called, loading, data, error } ] = useLazyQuery(props.listQuery, {
        variables: {
            offset: 0,
            limit: 10
        },
        fetchPolicy: "cache-and-network"
    });
    React.useEffect(() => {
        getList({
            variables: {
                offset: 0,
                limit: 10
            }
        })
    }, [props.listQuery, props.statusId]);


    React.useEffect(() => {
        error && enqueueSnackbar(`Server encountered an error: ${error.message}`, {variant: "error"})
    }, [error]);

    // React.useEffect(() => {
    //     if(data){
    //         props.handleTotalCount(props.tabIndex, data[props.objectName].totalCount);
    //     }
    // }, [data]);

    const changePage = (page, rowsPerPage) => {
        getList({
            variables: {
                offset: page*rowsPerPage,
                limit: rowsPerPage
            },
        })
    };
    const options = {
        filterType: 'checkbox',
        enableNestedDataAccess: ".",
        elevation: 0,
        count: !loading && !error && data? data[props.objectName].totalCount: 0,
        search: false,
        filter: false,
        viewColumns: false,
        download: false,
        print: false,
        serverSide: true,
        onRowClick: (rowData, rowMeta) => {
            history.push(`${props.detailUrl}/${rowData[0]}`);
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                case'changeRowsPerPage':
                    changePage(tableState.page, tableState.rowsPerPage);
                    break;
            }
        },
    };
    const [columns, setColumns] = React.useState(props.columns);
    return (
        <React.Fragment>
            {!error ?
                < MUIDataTable
                    key={`${props.tabIndex}-table`}
                    data={data? data[props.objectName].nodes: [{id:'Loading'}]}
                    columns={columns}
                    options={options}
                />
                : null}
        </React.Fragment>
    );
}