import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import Edit from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/moment';
import Paper from '@material-ui/core/Paper';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import {gql} from "@apollo/client/core";
import {useMutation} from "@apollo/client";
import {useSnackbar} from "notistack";
import ShipmentStatusUpdate from "./ShipmentStatusUpdate";
import {getCurrentDateTime} from "../../utils/DateFormatMoment";
import FileUploader from "../uploads/FileUploader";
import FileList from "../uploads/FileList";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {statusDisplayMap} from "../../views/shipments/ShipmentView";

const useStyles = makeStyles(theme => ({
    summaryContainer:{
        marginTop: theme.spacing(2),
    },
    summaryTable: {
        marginBottom:  theme.spacing(2)
    },
    summaryTableRow: {
    },
    summaryTableThCell:{
        width:250,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    summaryTableCell:{
        maxWidth:100,
        fontSize: 16,
        fontWeight: 600,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    summaryTableThCell1:{
        width:250
    },
    cardHeaderRoot: {
        position: 'absolute'
    }
}));


const UPDATE_SHIPMENT_MUTATION = gql`
    mutation ($id: Int!, $shipment: ShipmentPatch!){
     updateShipment(
       input: {
          id: $id
          patch: $shipment
      }
    ){
    shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        updatedAt
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
          warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
    }
`;

const UPDATE_SHIPMENT_NAME_MUTATION = gql`
mutation($id : Int!){
updateShipmentName(
    input: {
        shipmentid:$id
      }
   ) 
   {
    shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        updatedAt
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
          warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
   
  }
}
`;

const SHIPMENT_CREATE_DOCUMENTS_MUTATION = gql`
mutation ($id : Int!, $warehouseDocuments: [FkWarehouseDocumentShipmentWarehouseDocumentsCreateInput!]){
    updateShipment(
    input: {
      id: $id  
      patch: {
        warehouseDocumentsUsingId: {
            create: $warehouseDocuments
        }
      }
    }
)   {
    shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        updatedAt
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
         warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
   
  }
}
`;

const statusMap = {
    'Requested': 'primary',
    'Shipped': 'secondary',
    'Received': 'primary',
};
export default function ShipmentSummaryDetails(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [shipmentValues, setShipmentValues] = React.useState(props.shipment);
    const [addDocuments, setAddDocuments] = React.useState(false);
    const [supplierId, setSupplierId] = React.useState(39);
    const [documents, setDocuments] = React.useState(props.shipment.warehouseDocuments.nodes);
    const [updateShipmentNameMutation, {error: updateShipmentNameMutationError}] = useMutation(UPDATE_SHIPMENT_NAME_MUTATION, {
        onCompleted: ({updateShipmentName: {shipment}}) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
            props.handleUpdateShipment(shipment);
        }
    });
    const [updateShipment, {loading: mutationLoading, error: mutationError}] = useMutation(UPDATE_SHIPMENT_MUTATION,{
        onCompleted: ({ updateShipment: { shipment } }) => {
            setShipmentValues(shipment);
            updateShipmentNameMutation({
                variables: {
                    id: shipment.id
                }
            });
        }
    });

    const handleDocumentCreate = (shipment) => {
        setDocuments(documents => [...shipment.warehouseDocuments.nodes]);
    };
    const [createShipmentDocument, {loading: createShipmentDocumentMutationLoading, error: createShipmentDocumentMutationError}] = useMutation(SHIPMENT_CREATE_DOCUMENTS_MUTATION,{
        onCompleted: ({ updateShipment: { shipment } }) => {
            handleDocumentCreate(shipment);
        }
    });

    React.useEffect(() => {
        (mutationError || updateShipmentNameMutationError || createShipmentDocumentMutationError) && enqueueSnackbar(`Server encountered an error: ${mutationError? mutationError.message : updateShipmentNameMutationError.message}`, {variant: "error"})
    }, [mutationError, updateShipmentNameMutationError, createShipmentDocumentMutationError]);


    const handleShippingDateChange = date => {
        setShipmentValues({ ...shipmentValues, ['shippingDate']: date });
    };

    const handleEstimatedArrivalDateChange = date => {
        setShipmentValues({ ...shipmentValues, ['estimatedArrivalDate']: date });
    };
    const handleInvoiceDueDateChange = date => {
        setShipmentValues({ ...shipmentValues, ['invoiceDueDate']: date });
    };
    const handleSupplierChange = (event) => {
        setSupplierId(event.target.value);
    };
    const [editMode, setEditMode] = React.useState(false);
    function saveShipment() {
        let {id, nodeId, __typename, status, stockOrderLines, warehouseDocuments, supplier, ...s} = shipmentValues;
        s['updatedAt'] = getCurrentDateTime();
        s['supplierId'] = supplierId;
        updateShipment({
            variables : {
                id: id,
                shipment: s
            }
        })
    }
    function handleEditClick() {
        setEditMode(true);
    }
    function handleSaveClick() {
        setEditMode(false);
        saveShipment()
    }
    const handleChange = name => event => {
        let value = event.target.value;
        if(event.target.type === 'number'){
            value = parseFloat(value)
        }
        setShipmentValues({ ...shipmentValues, [name]: value });
    };

    const handleFileUploaded = (file) => {
        createShipmentDocument({
            variables : {
                id: props.shipment.id,
                warehouseDocuments: {
                    fileName: file.file.name,
                    fileType: file.file.type,
                    fileSize: file.file.size,
                    filePath: file.fileUrl
                }
            }
        })
    };
    const handleDocumentDeleted = (id) => {
      setDocuments(documents.filter(document => document.id !== id))
    };

    const totalBlinds = () => {
        return props.stockOrderLines? props.stockOrderLines.reduce((total, record) => record.shipmentQty + total, 0): 0
    };
    return (
            <React.Fragment>
            <Card className={classes.root}>
                <CardHeader
                    title={statusDisplayMap[props.shipment.status.name]}
                    titleTypographyProps={
                        {
                            color: statusMap[props.shipment.status.name]
                        }
                    }
                    action={
                        !editMode ?
                        <IconButton onClick={handleEditClick}>
                        <Edit/>
                        </IconButton>:
                        <IconButton onClick={handleSaveClick} disabled={mutationLoading}>
                        <CheckIcon/>
                        </IconButton>
                    }
                    classes={{
                    }}
                />
                <CardContent>
                    {props.shipment ?
                        !editMode ?
                            <React.Fragment>
                            <Typography variant="h6">Shipping</Typography>
                            <Table className={classes.summaryTable} size="small">
                                <TableBody>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                            Consignment Number
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell}>
                                            {shipmentValues.consignmentNumber}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                            Shipping Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell}>
                                            {shipmentValues.shippingDate && <Moment format="DD/MM/YYYY">{shipmentValues.shippingDate}</Moment>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                            Estimated Arrival Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell}>
                                            {shipmentValues.estimatedArrivalDate && <Moment format="DD/MM/YYYY">{shipmentValues.estimatedArrivalDate}</Moment>}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                                <Typography variant="h6">Invoicing</Typography>
                            <Table className={classes.summaryTable} size="small">
                                    <TableBody>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                                Invoice Number
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {shipmentValues.invoiceNumber}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                                Invoice Value
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {shipmentValues.invoiceValue? `$${shipmentValues.invoiceValue}`: null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                                Total Quantity
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {totalBlinds()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                               Manufacturer
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {shipmentValues.supplier? shipmentValues.supplier.name: null}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                                Due Date
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {shipmentValues.invoiceDueDate && <Moment format="DD/MM/YYYY">{shipmentValues.invoiceDueDate}</Moment>}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Typography variant="h6">Shipping</Typography>
                            <Table className={classes.summaryTable} size="small">
                                <TableBody>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell className={classes.summaryTableThCell1} component="th" scope="row">
                                            Consignment Number
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell1}>
                                            <TextField
                                                id="standard-consignmentNumber"
                                                className={classes.textField}
                                                value={shipmentValues.consignmentNumber||''}
                                                fullWidth={true}
                                                onChange={handleChange('consignmentNumber')}
                                                margin="dense"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell component="th" scope="row" className={classes.summaryTableThCell1}>
                                            Shipping Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell1}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="DD/MM/YYYY"
                                                    margin="dense"
                                                    id="date-picker-inline-shippingDate"
                                                    label=""
                                                    autoOk={true}
                                                    fullWidth={true}
                                                    value={shipmentValues.shippingDate||null}
                                                    onChange={handleShippingDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.summaryTableRow}>
                                        <TableCell component="th" scope="row" className={classes.summaryTableThCell1}>
                                            Estimated Arrival Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.summaryTableCell1}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="DD/MM/YYYY"
                                                    margin="dense"
                                                    id="date-picker-inline-estimatedArrivalDate"
                                                    label=""
                                                    autoOk={true}
                                                    fullWidth={true}
                                                    value={shipmentValues.estimatedArrivalDate||null}
                                                    onChange={handleEstimatedArrivalDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                                <Typography variant="h6">Invoicing</Typography>
                                <Table className={classes.summaryTable} size="small">
                                    <TableBody>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell1} component="th" scope="row">
                                                Invoice Number
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell1}>
                                                <TextField
                                                    id="standard-invoiceNumber"
                                                    className={classes.textField}
                                                    value={shipmentValues.invoiceNumber||''}
                                                    fullWidth={true}
                                                    onChange={handleChange('invoiceNumber')}
                                                    margin="dense"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell1} component="th" scope="row">
                                                Invoice Value
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell1}>
                                                <TextField
                                                    id="standard-invoiceValue"
                                                    type="number"
                                                    className={classes.textField}
                                                    fullWidth={true}
                                                    value={shipmentValues.invoiceValue||''}
                                                    onChange={handleChange('invoiceValue')}
                                                    margin="dense"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell1} component="th" scope="row">
                                                Total Qty
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell}>
                                                {totalBlinds()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell className={classes.summaryTableThCell1} component="th" scope="row">
                                                Manufacturer
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell1}>
                                                <Select
                                                    value={39}
                                                    onChange={handleSupplierChange}
                                                    displayEmpty
                                                    className={classes.selectEmpty}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={39}>Blaze</MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.summaryTableRow}>
                                            <TableCell component="th" scope="row" className={classes.summaryTableThCell1}>
                                                Due Date
                                            </TableCell>
                                            <TableCell align="left" className={classes.summaryTableCell1}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        disableToolbar
                                                        variant="inline"
                                                        format="DD/MM/YYYY"
                                                        margin="dense"
                                                        id="date-picker-inline-invoiceDueDate"
                                                        label=""
                                                        autoOk={true}
                                                        fullWidth={true}
                                                        value={shipmentValues.invoiceDueDate||null}
                                                        onChange={handleInvoiceDueDateChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                        </React.Fragment>
                        : null}
                        <FileList documents={documents} handleDocumentDeleted={handleDocumentDeleted}/>
                        <Button size="small" variant="contained" color={addDocuments? 'secondary' : 'primary'} component="span" onClick={() => setAddDocuments(!addDocuments)}>
                            {addDocuments? 'Cancel' : 'Add Documents'}
                        </Button>
                        {addDocuments && <FileUploader handleFileUploaded={handleFileUploaded}/>}
                </CardContent>
                {props.shipment.status.name !== 'Received'?
                <CardActions>
                    <ShipmentStatusUpdate {...props} editMode={editMode}/>
                </CardActions>
                : null}
            </Card>
            </React.Fragment>
    )
}