import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FindShipment from "../shipment/FindShipment";
import {useSnackbar} from "notistack";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {getCurrentDateTime} from "../../utils/DateFormatMoment";

const ORDER_STATUS_MUTATION = gql`
    mutation ($input: UpdateStockOrderInput!){
     updateStockOrder(
       input: $input
    ){
     stockOrder{
        nodeId
        id
        status{
          name
        }
        stockOrderLinesByOrderId{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
           }
          }
       }
     }
    }
`;

const CREATE_SHIPMENT_MUTATION = gql`
mutation ($stockOrderLines: [StockOrderLineStockOrderLinesPkeyConnect!]){
createShipment(
    input: {
      shipment: {
        statusId: 1
        stockOrderLinesUsingId: {
          connectById: $stockOrderLines
        }
      }
    }
) {
  shipment {
    id
    status{
      name
    }
    stockOrderLines {
      nodes {
        id
        fabric
        colour
        width
        depth
      }
    }
  }
}
}
`;

const CONNECT_SHIPMENT_MUTATION = gql`
mutation ($id : Int!, $stockOrderLines: [StockOrderLineStockOrderLinesPkeyConnect!]){
updateShipment(
    input: {
      id: $id  
      patch: {
        stockOrderLinesUsingId: {
          connectById: $stockOrderLines
        }
      }
    }
) {
  shipment {
    id
    status{
      name
    }
    stockOrderLines {
      nodes {
        id
        fabric
        colour
        width
        depth
      }
    }
  }
}
}
`;

const UPDATE_SHIPMENT_NAME_MUTATION = gql`
mutation($id : Int!){
updateShipmentName(
    input: {
        shipmentid:$id
        }
   ) 
  {
    clientMutationId
  }
}
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    paperFullWidth: {
        overflowY: 'visible'
    },
    dialogContentRoot: {
        overflowY: 'visible'
    }
}));

const statusIdMap = {
    'Draft': 1,
    'Sent': 2,
    'Cancel': 4
};

const statusButtonColorMap = {
    'Draft': 'default',
    'Sent': 'primary',
    'Cancel': 'secondary'
};

const statusButtonTextMap = {
    'Draft': 'Draft',
    'Sent': 'Sent',
    'Cancel': 'Cancel'
};

const statusConfirmationTextMap = {
    'Draft': 'Change Status to Draft?',
    'Sent': 'Confirm Order?',
    'Cancel': 'Cancel Order?'
};
const statusConfirmationSubTextMap = (status, data) => {
    if(status === 'Sent'){
        return `Confirming this order will update our stock levels with ${data.totalBlinds} new blinds in transit.`
    }
    else {
        return `Order has ${data.totalBlinds} blinds`
    }
};




export default function OrderMarkSent(props) {
    const classes = useStyles();
    let history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [selectedShipment, setSelectedShipment] = React.useState(null);
    const [selectedShipmentError, setSelectedShipmentError] = React.useState(false);
    const [createShipmentCheckbox, setCreateShipmentCheckbox] = React.useState(true);
    const [redirect, setRedirect] = React.useState(false);
    const handlePerformRedirect = (shipment) => {
        history.push(`/shipments/${shipment.id}`);
    };
    const [sendOrder, { data: sentOrder,  loading: mutationLoading, error: mutationError}] = useMutation(ORDER_STATUS_MUTATION, {
        onCompleted: ({updateStockOrder: {stockOrder}}) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
            props.handleUpdateOrder({stockOrder: stockOrder});
        }
    });
    const [updateShipmentNameMutation, {error: updateShipmentNameMutationError}] = useMutation(UPDATE_SHIPMENT_NAME_MUTATION);
    const [createShipment, { data: createdShipment,  loading: createShipmentMutationLoading, error: createShipmentMutationError}] = useMutation(CREATE_SHIPMENT_MUTATION, {
        onCompleted: ({createShipment: {shipment}}) => {
           updateShipmentNameMutation({
               variables: {
                   id : shipment.id
               }
           });
           handlePerformRedirect(shipment);
        }
    });
    const [connectShipment, { data: connectedShipment,  loading: connectShipmentMutationLoading, error: connectShipmentMutationError}] = useMutation(CONNECT_SHIPMENT_MUTATION, {
        onCompleted: ({updateShipment: {shipment}}) => {
            handlePerformRedirect(shipment);
        }
    });
    React.useEffect(() => {
        (mutationError || createShipmentMutationError || connectShipmentMutationError || updateShipmentNameMutationError) && enqueueSnackbar(`Server encountered an error`, {variant: "error"})
    }, [mutationError, createShipmentMutationError, connectShipmentMutationError, updateShipmentNameMutationError]);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: mutationLoading,
    });
    const handleSelectedShipment = (value) => {
      setSelectedShipment(value)
    };
    const handleSentOrder = () => {
        if(props.status === 'Sent') {
            if (!createShipmentCheckbox && !selectedShipment) {
                setSelectedShipmentError(true);
                return;
            }
            else {
                sendOrder({
                    variables: {
                        input: {
                            id: parseInt(props.orderId),
                            patch: {
                                statusId: statusIdMap[props.status],
                                updatedAt: getCurrentDateTime(),
                                ...(props.status === "Sent" && {sentAt: getCurrentDateTime()})
                            }
                        }
                    }
                });
                if (createShipmentCheckbox) {
                    createShipment({
                        variables: {
                            stockOrderLines: props.records.map(record => ({id: record.id}))
                        }
                    });
                } else {
                    connectShipment({
                        variables: {
                            id: selectedShipment.value,
                            stockOrderLines: props.records.map(record => ({id: record.id}))
                        }
                    });
                }
            }
        }
        else {
            sendOrder({
                variables: {
                    input: {
                        id: parseInt(props.orderId),
                        patch: {
                            statusId: statusIdMap[props.status],
                            updatedAt: getCurrentDateTime()
                        }
                    }
                }
            });
            setOpen(false);
        }
    };
    const handleCheckboxChange = (event) => {
        setCreateShipmentCheckbox(event.target.checked)
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
        <div>
            <Button
                variant="contained"
                disabled={props.loading}
                size="large"
                color={statusButtonColorMap[props.status]}
                onClick={handleClickOpen}

            >
                {statusButtonTextMap[props.status]}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                // maxWidth="md"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paperFullWidth: classes.paperFullWidth
                }}
            >
                <DialogTitle id="alert-dialog-title">{statusConfirmationTextMap[props.status]}</DialogTitle>
                <DialogContent  classes={{
                    root: classes.dialogContentRoot
                }}>
                    <DialogContentText id="alert-dialog-description">
                        {statusConfirmationSubTextMap(props.status, {totalBlinds: props.records? props.records.reduce((total, record) => record.qty + total, 0): 0})}
                    </DialogContentText>
                    {props.status === 'Sent'?
                        <React.Fragment>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={createShipmentCheckbox}
                                        onChange={handleCheckboxChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Add to new shipment"
                            />
                            {!createShipmentCheckbox && <FindShipment selectedShipmentError={selectedShipmentError} selectedShipment={selectedShipment} handleSelectedShipment={handleSelectedShipment}/>}
                        </React.Fragment>
                    : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={mutationLoading}
                        onClick={handleSentOrder}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
        </React.Fragment>
    );
}