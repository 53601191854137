import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import Button from '@material-ui/core/Button';
import {
    useParams
} from "react-router-dom";
import {gql} from "@apollo/client/core";
import {useQuery} from "@apollo/client";
import ShipmentDetails from "../../components/shipment/ShipmentDetails";
import ShipmentLinesTable from "../../components/shipment/ShipmentLinesTable";
import Paper from "@material-ui/core/Paper/Paper";
import Grid from "@material-ui/core/Grid";
import {useSnackbar} from "notistack";
import ShipmentStepper from "../../components/shipment/ShipmentStepper";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

const GET_SHIPMENT_QUERY = gql`
query($id: Int!){
   shipment(id: $id){
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        createdAt
        updatedAt
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
        }
        warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
  }
}
`;

export default function ShipmentDetailView(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let { id } = useParams();
    const { data, called, loading, error, refetch } = useQuery(GET_SHIPMENT_QUERY, {
        notifyOnNetworkStatusChange: true,
        variables: {
            id: parseInt(id)
        }
    });
    React.useEffect(() => {
        error && enqueueSnackbar(`Server encountered an error: ${error.message}`, {variant: "error"})
    }, [error]);
    const [shipment, setShipment] = React.useState(null);
    const [stockOrderLines, setStockOrderLines] = React.useState(null);
    const [stockOrderLinesCount, setStockOrderLinesCount] = React.useState(null);
    const handleUpdateStockOrderLines = (rows) => {
        setStockOrderLines(rows)
    };
    React.useEffect(() => {
        if(called && data && data.shipment){
            setShipment(data.shipment);
            setStockOrderLines(data.shipment.stockOrderLines.nodes.map(line => ({...line, shipmentQty: line.qty})));
            setStockOrderLinesCount(data.shipment.stockOrderLines.totalCount);
        }
    }, [data, called]);
    const handleUpdateShipment = (shipment) => {
      setShipment(shipment)
    };
    return (
        <DocumentTitle title="View | Shipment">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    {stockOrderLines && shipment && <ShipmentStepper shipment={shipment} status={shipment.status.name} orderDate={stockOrderLines[0].order.sentAt}/>}
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            {stockOrderLines && <ShipmentLinesTable shipment={shipment} stockOrderLines={stockOrderLines} stockOrderLinesCount={stockOrderLinesCount} handleUpdateStockOrderLines={handleUpdateStockOrderLines}/>}
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            {shipment && stockOrderLines && <ShipmentDetails shipment={shipment} handleUpdateShipment={handleUpdateShipment} stockOrderLines={stockOrderLines} stockOrderLinesCount={stockOrderLinesCount}/>}
                        </Grid>

                    </Grid>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

