import axios from 'axios';

const warehouseQueueInstance = axios.create({
    baseURL: 'https://lcfqw5vfr8.execute-api.ap-southeast-2.amazonaws.com/prod',
    headers: {
        "x-api-key": process.env.REACT_APP_WAREHOUSE_QUEUE_X_API_KEY
    }
});

export {
    warehouseQueueInstance
};
