import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {makeStyles} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FindShipment from "./FindShipment";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {useMutation} from "@apollo/client";
import clsx from "clsx";
import {useSnackbar} from "notistack";
import {gql} from "@apollo/client/core";
import {getCurrentDateTime} from "../../utils/DateFormatMoment";
import {warehouseQueue} from "../../api/warehouseAppQueue";

const useStyles = makeStyles(theme => ({
    buttonGroupRoot: {
        float: 'right'
    }
}));


const statusMap = {
    'Requested': 'Shipped',
    'Shipped': 'Received',
};

const statusUpdateMap = {
    'Requested': 2,
    'Shipped': 3,
};



const SHIPMENT_STATUS_MUTATION = gql`
    mutation ($input: UpdateShipmentInput!){
     updateShipment(
       input: $input
    ){shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
           warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
    }
`;
const SHIPMENT_CREATE_LINES_MUTATION = gql`
mutation ($stockOrderLines: [FkStockOrderLineShipmentStockOrderLinesCreateInput!]){
createShipment(
    input: {
      shipment: {
        statusId: 1
        stockOrderLinesUsingId: {
           create: $stockOrderLines
        }
      }
    }
){ shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
           warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
}
`;
const SHIPMENT_UPDATE_LINES_MUTATION = gql`
   mutation ($id : Int!, $statusId : Int!, $updatedAt: Datetime!, $stockOrderLines: [StockOrderLineOnStockOrderLineForFkStockOrderLineShipmentUsingStockOrderLinesPkeyUpdate!]){
    updateShipment(
    input: {
      id: $id  
      patch: {
        statusId: $statusId, 
        updatedAt: $updatedAt,
        stockOrderLinesUsingId: {
          updateById: $stockOrderLines
        }
      }
    }
) {shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
           warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
}
`;

const SHIPMENT_ADD_LINES_MUTATION = gql`
   mutation ($id : Int!, $stockOrderLines: [FkStockOrderLineShipmentStockOrderLinesCreateInput!]){
    updateShipment(
    input: {
      id: $id  
      patch: {
        stockOrderLinesUsingId: {
            create: $stockOrderLines
        }
      }
    }
) {shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
         warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
}
`;
const SHIPMENT_RECEIVED_DISCREPANCY_CREATE_MUTATION = gql`
mutation ($id : Int!, $shipmentsReceivedDiscrepancies: [FkShipmentsReceivedDiscrepancyShipmentShipmentsReceivedDiscrepanciesCreateInput!]){
    updateShipment(
    input: {
      id: $id  
      patch: {
        shipmentsReceivedDiscrepanciesUsingId: {
            create: $shipmentsReceivedDiscrepancies
        }
      }
    }
) {shipment{
        nodeId
        id
        consignmentNumber
        invoiceNumber
        shippingDate
        estimatedArrivalDate
        name
        invoiceValue
        invoiceQty
        supplier{
            name
        }
        invoiceDueDate
        status{
          name
        }
        stockOrderLines{ 
           totalCount
           nodes{
              id
              fabric
              colour
              width
              depth
              qty
              baseBar
              bracket
              chainLength
              rollDirection
              controlSide
              sticker
              orderId
              stockedBlindId
              stockedBlind{
                id
                xeroInventoryCode
                stockedBlindPrices {
                nodes{
                    amount
                  }
                }
              }
              received
              order{
                sentAt
              }
           }
          }
       warehouseDocuments{
            totalCount
            nodes{
                id
                fileName
                fileSize
                filePath
                fileType
            }
         }
        }
    }
}`;


const UPDATE_STOCKED_BLIND_SHIPMENT_RECEIVED_MUTATION = gql`
mutation($id : Int!){
updateStockedBlindShipmentReceived(
    input: {
        shipmentid:$id
        }
   ) 
  {
    clientMutationId
  }
}
`;

const MARK_ORDER_RECEIVED_MUTATION = gql`
mutation($id : Int!){
    markOrderReceived(
        input: {
        shipmentid:$id
    }
) {
        clientMutationId
    }
}
`;


const statusConfirmationSubTextMap = (status, data) => {
    if(status === 'Shipped'){
        return `Confirming this shipment will create an invoice in Xero for the value of ${data.totalBlindsShipment} blinds.`
    }
    if(status === 'Received'){
        return `Confirming this shipment will increase stock levels in Xero by ${data.totalBlindsShipment} blinds, and update these blinds in the Portal from In Transit to In stock.`
    }
    else {
        return `Order has ${data.totalBlindsShipment} blinds`
    }
};

export default function ShipmentStatusUpdate(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [selectedShipment, setSelectedShipment] = React.useState(null);
    const [selectedShipmentError, setSelectedShipmentError] = React.useState(false);
    const [createShipmentCheckbox, setCreateShipmentCheckbox] = React.useState(true);
    const [savedShipment, setSavedShipment] = React.useState(null);
    const [shipmentReceivedMutation, {error: shipmentReceivedMutationError}] = useMutation(UPDATE_STOCKED_BLIND_SHIPMENT_RECEIVED_MUTATION);
    const [markOrderReceivedMutation, {error: markOrderReceivedMutationError}] = useMutation(MARK_ORDER_RECEIVED_MUTATION);

    const handleShipmentReceived = async (shipment) => {
        if(shipment.status.name === 'Received') {
            //Shipment Received
            shipmentReceivedMutation({
                variables:{
                    id : shipment.id
                }
            });
            markOrderReceivedMutation({
                variables:{
                    id : shipment.id
                }
            });
        }
        else if(shipment.status.name === 'Shipped') {
            //Create Xero Invoice
            const queueForInvoiceGeneration = async () => {
                const resp = await warehouseQueue(
                    {
                        function: 'shipment-received',
                        data: shipment
                    }
                );
                console.log(resp);
            };
            await queueForInvoiceGeneration();
        }
    };
    const [updateShipment, { data: updatedShipment,  loading: mutationLoading, error: mutationError}] = useMutation(SHIPMENT_STATUS_MUTATION, {
        onCompleted: ({ updateShipment: { shipment } }) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
            handleShipmentReceived(shipment);
            props.handleUpdateShipment(shipment);
        }
    });
    const [updateShipmentLines, { data: updatedShipmentLines,  loading: updatedShipmentLinesLoading, error: updatedShipmentLinesMutationError}] = useMutation(SHIPMENT_UPDATE_LINES_MUTATION, {
        onCompleted: async ({ updateShipment: { shipment } }) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
            await handleShipmentReceived(shipment);
            props.handleUpdateShipment(shipment);
        }
    });
    const [createShipmentLines, { data: createdShipmentLines,  loading: createdShipmentLinesLoading, error: createdShipmentLinesMutationError}] = useMutation(SHIPMENT_CREATE_LINES_MUTATION);
    const [shipmentAddLines, { data: shipmentAddedLines,  loading: shipmentAddLinesLoading, error: shipmentAddLinesMutationError}] = useMutation(SHIPMENT_ADD_LINES_MUTATION);
    const [createShipmentsReceivedDiscrepancy, { data: createdShipmentsReceivedDiscrepancy,  loading: createShipmentsReceivedDiscrepancyLoading, error: createShipmentsReceivedDiscrepancyMutationError}] = useMutation(SHIPMENT_RECEIVED_DISCREPANCY_CREATE_MUTATION);


    React.useEffect(() => {
        (mutationError || updatedShipmentLinesMutationError || createdShipmentLinesMutationError || shipmentAddLinesMutationError || createShipmentsReceivedDiscrepancyLoading) && enqueueSnackbar(`Server encountered an error`, {variant: "error"})
    }, [mutationError, updatedShipmentLinesMutationError, createdShipmentLinesMutationError, shipmentAddLinesMutationError, createShipmentsReceivedDiscrepancyMutationError]);


    const buttonClassname = clsx({
        [classes.buttonSuccess]: mutationLoading,
    });
    const handleSelectedShipment = (value) => {
        setSelectedShipment(value)
    };
    const getLinesToUpdate = () => {
        let orderLines = props.stockOrderLines;
        orderLines = orderLines.map(line => ({...line,  qty: line.shipmentQty}));
        let updateLines = orderLines.map(line => ({
            id: line.id,
            patch: {
                qty: line.qty,
                received: props.shipment.status.name === 'Shipped',
            }
        }));
        return updateLines
    };

    const getLinesToCreate = () => {
        let orderLines = props.stockOrderLines.filter(line => line.shipmentQty < line.qty);
        orderLines = orderLines.map(line => ({...line, qty: line.qty - line.shipmentQty}));
        let createLines = orderLines.map(({
              __typename,
              id,
              shipmentQty,
              ...rest
          })=> ({
                ...rest
        }));
        return createLines
    };

    const getDiscrepancies = () => {
        let orderLines = props.stockOrderLines.filter(line => line.shipmentQty < line.qty);
        orderLines = orderLines.map(line => ({...line, qty: line.qty - line.shipmentQty}));
        let createLines = orderLines.map(({
                                              __typename,
                                              id,
                                              ...rest
                                          })=> ({
            stockedBlindId : rest.stockedBlindId,
            qtyShipped : rest.qty+rest.shipmentQty,
            qtyReceived: rest.shipmentQty
        }));
        return createLines
    };

    const handleSentOrder = () => {
        if(totalBlindsShipment() !== totalBlinds())
        {
            if(!createShipmentCheckbox && !selectedShipment) {
                setSelectedShipmentError(true);
                return;
            }
            if(createShipmentCheckbox) {
                updateShipmentLines({
                    variables: {
                        id : parseInt(props.shipment.id),
                        updatedAt: getCurrentDateTime(),
                        statusId: statusUpdateMap[props.shipment.status.name],
                        stockOrderLines: getLinesToUpdate()
                    }
                });
                createShipmentLines({
                    variables: {
                        stockOrderLines: getLinesToCreate()
                    }
                });
            }
            else {
                updateShipmentLines({
                    variables: {
                        id : parseInt(props.shipment.id),
                        updatedAt: getCurrentDateTime(),
                        statusId: statusUpdateMap[props.shipment.status.name],
                        stockOrderLines:getLinesToUpdate()
                    }
                });
                shipmentAddLines({
                    variables: {
                        id: selectedShipment.value,
                        shipmentsReceivedDiscrepancies: getLinesToCreate()
                    }
                });

            }
            if(props.shipment.status.name === 'Shipped'){
                //Log discrepancy
                createShipmentsReceivedDiscrepancy({
                    variables: {
                        id: parseInt(props.shipment.id),
                        shipmentsReceivedDiscrepancies: getDiscrepancies()
                    }
                });
            }
        }
        else {
            updateShipment({
                variables: {
                    input: {
                        id : parseInt(props.shipment.id),
                        patch: {
                            statusId: statusUpdateMap[props.shipment.status.name],
                            updatedAt: getCurrentDateTime(),
                            stockOrderLinesUsingId: {
                                updateById: getLinesToUpdate()
                            }
                        }
                    }
                }
            });
        }
        if(!createShipmentCheckbox && !selectedShipment) {
            setSelectedShipmentError(true);
            return;
        }
        setOpen(false)
    };
    const handleCheckboxChange = (event) => {
        setCreateShipmentCheckbox(event.target.checked)
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const totalBlindsShipment = () => {
        return props.stockOrderLines? props.stockOrderLines.reduce((total, record) => record.shipmentQty + total, 0): 0
    };
    const totalBlinds = () => {
        return props.stockOrderLines? props.stockOrderLines.reduce((total, record) => record.qty + total, 0): 0
    };
    const allBlindsConfirmed = () => {
        return  props.stockOrderLines? props.stockOrderLines.filter(line => !line.received).length === 0 : false
    };
    return (
        <React.Fragment>
            <ButtonGroup classes={{root: classes.buttonGroupRoot}} variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                <Button size="large" onClick={handleClickOpen} disabled={props.editMode || !allBlindsConfirmed()}>
                    {statusMap[props.shipment.status.name]}
                </Button>
            </ButtonGroup>
            <Dialog
                open={open}
                onClose={handleClose}
                // maxWidth="md"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paperFullWidth: classes.paperFullWidth
                }}
            >
                <DialogTitle id="alert-dialog-title">{`Confirm ${statusMap[props.shipment.status.name]}`}</DialogTitle>
                <DialogContent  classes={{
                    root: classes.dialogContentRoot
                }}>
                    <DialogContentText id="alert-dialog-description">
                        {statusConfirmationSubTextMap(statusMap[props.shipment.status.name], {totalBlindsShipment: totalBlindsShipment(), totalBlinds: totalBlinds()})}
                    </DialogContentText>
                    {totalBlindsShipment() !== totalBlinds() ?
                        <React.Fragment>
                            <DialogContentText id="alert-dialog-description">
                                Add the missing blinds to a new or existing shipment
                            </DialogContentText>
                            <FormControlLabel
                            control={
                                <Checkbox
                                    checked={createShipmentCheckbox}
                                    onChange={handleCheckboxChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Add to new shipment"
                            />
                            {!createShipmentCheckbox && <FindShipment selectedShipmentError={selectedShipmentError} selectedShipment={selectedShipment} handleSelectedShipment={handleSelectedShipment}/>}
                        </React.Fragment>
                    : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={mutationLoading || !allBlindsConfirmed}
                        onClick={handleSentOrder}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
