import React from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import Button from '@material-ui/core/Button';
import Moment from "react-moment";
import {getTableRowId} from "../../utils/muiDatatablesUtils";
import {gql} from "@apollo/client/core";
import StatusTabList from "../../components/StatusTabList";
import OrderListActions from "../../components/orders/OrderListActions";
import StatusList from "../../components/StatusList";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

const statusColorMap = {
    'Draft': 'inherit',
    'Sent': '#2979ff',
    'Cancelled': '#ab003c',
    'Received': '#4caf50'
};

const GET_ORDERS_QUERY = gql`
query($offset: Int!, $limit: Int!){
   stockOrders(offset:$offset, first:$limit, orderBy: [UPDATED_AT_DESC]){
    pageInfo {
      hasNextPage
    }
    totalCount
    nodes{
      id
      createdAt
      updatedAt
      status{
        name
      }
      stockOrderLinesByOrderId{
        totalCount
        aggregates {
          sum {
            qty
          }
        } 
        nodes {
            qty
        }
      }
    }
  }
}
`;

export default function OrderView(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const statusList = [
        {
            name: 'Draft',
            id: 1
        },
        {
            name: 'Sent',
            id: 2
        },
        {
            name: 'Received',
            id: 3
        }
    ];
    const [columns, setColumns] = React.useState([
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "status.name",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value && <Typography style={{color: statusColorMap[value.trim()]}}>{value}</Typography>
                )
            }
        },
        {
            name: "stockOrderLinesByOrderId.nodes",
            label: "Details",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                   value && <Typography>{value.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.qty
                    , 0
                   )} blinds</Typography>
                )
            }
        },
        {
            name: "updatedAt",
            label: "Updated",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value && <Moment fromNow>{value}</Moment>
                )
            }
        }
    ]);
    return (
        <DocumentTitle title="List | Orders">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <div className={classes.action}>
                        <Button variant="contained" color="primary" component={RouterLink} to="/orders/create">Create Order</Button>
                    </div>
                    <StatusList  statusList={statusList} columns={columns} listQuery={GET_ORDERS_QUERY} objectName="stockOrders" detailUrl='/orders'/>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

