import MUIDataTable from "mui-datatables";
import React, {useEffect} from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { gql, useQuery, useMutation } from '@apollo/client';

const GET_STOCK_QUERY = gql`
query StockedBlinds($offset: Int!, $limit: Int!) {
  stockedBlinds(first: $limit, offset: $offset, orderBy: [COLOUR_BY_COLOUR_ID__NAME_ASC, COLOUR_GROUP_BY_COLOUR_GROUP_ID__NAME_ASC, WIDTH_ASC]){
    totalCount
    pageInfo {
      hasNextPage
    }
    nodes{
      id
      width
      depth
      shelfQuantity
      uncommittedQuantity
      inTransit
      colour{
        name
      }
      colourGroup{
        name
      }
      supplier{
        name
      }
      baseBarColour{
        name
      }
    }
  }
}
`;

const UPDATE_SHELF_QUANTITY_QUERY = gql`
    mutation ($id: Int!, $shelfQuantity: Int!){
     updateStockedBlind(
       input: {
          id: $id
          patch: {
            shelfQuantity: $shelfQuantity
        }
      }
    ){
     stockedBlind{
      nodeId
      id
      shelfQuantity 
      uncommittedQuantity
     }
    }
    }
`;
export {GET_STOCK_QUERY}
function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export default function StockList(props) {
    const { data, loading, fetchMore, error } = useQuery(GET_STOCK_QUERY, {
        variables: {
            offset: 0,
            limit: 10
        },
        fetchPolicy: "cache-and-network"
    });
    const [updateShelfQuantity, { updatedShelfQuantity }] = useMutation(UPDATE_SHELF_QUANTITY_QUERY);
    const getTableRowId = (tableMeta) => {
        return tableMeta.tableData[tableMeta.rowIndex].id
    };
    const handleShelfQuantityUpdate = (value, tableMeta, updateValue) => {
        updateValue(value);
        updateShelfQuantity({variables: {id: getTableRowId(tableMeta), shelfQuantity: parseInt(value)}})

    };
    const changePage = (page, rowsPerPage) => {
        fetchMore({
            variables: {
                offset: page*rowsPerPage,
                limit: rowsPerPage
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                    stockedBlinds: {
                        totalCount: fetchMoreResult.stockedBlinds.totalCount,
                        pageInfo: fetchMoreResult.stockedBlinds.pageInfo,
                        nodes: getUniqueListBy([...prev.stockedBlinds.nodes, ...fetchMoreResult.stockedBlinds.nodes], 'id'),
                        __typename: 'StockedBlinds'
                    }
                });
            }
        })
    };
    const options = {
        filterType: 'checkbox',
        enableNestedDataAccess: ".",
        count: !loading? data.stockedBlinds.totalCount: 0,
        onTableChange: (action, tableState) => {
            //console.log(action, tableState);
            switch (action) {
                case 'changePage':
                case'changeRowsPerPage':
                    changePage(tableState.page, tableState.rowsPerPage);
                    break;
            }
        },
    };
    const [columns, setColumns] = React.useState([
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "colourGroup.name",
            label: "Fabric",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "colour.name",
            label: "Colour",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "width",
            label: "Width",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "depth",
            label: "Depth",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "shelfQuantity",
            label: "In Stock",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<TextField value={value || ''} type='number' />}
                        onChange={event => handleShelfQuantityUpdate(event.target.value, tableMeta, updateValue)}
                    />
                )
            }
        },
        {
            name: "uncommittedQuantity",
            label: "Available Stock",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "inTransit",
            label: "In Transit",
            options: {
                filter: false,
                sort: false,
            }
        },

    ]);
    return (
            <MUIDataTable
                title={"Stock"}
                data={data? data.stockedBlinds.nodes: [{id:'Loading'}]}
                columns={columns}
                options={options}
            />
    );
}