import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    menuButton: {
        marginRight: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        background: '#0078c8',
    },
    container: {
        marginTop: 30
    }
}));



export default function DashboardView(props) {
    const history = useHistory();
    const classes = useStyles();
    React.useEffect(() => {
        history.push('/shipments')
    }, []);
    return (
            <DocumentTitle title="Dashboard">
                <React.Fragment>
                    <CssBaseline />
                     <Container maxWidth="xl" className={classes.container}>
                        <div></div>
                    </Container>
                </React.Fragment>
            </DocumentTitle>
    );
}


