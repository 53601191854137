import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import StockList from "../components/stock/StockList";
import SkuList from "../components/stock/SkuList";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import SkuAddFormDialog from "../components/stock/SkuAddFormDialog";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    menuButton: {
        marginRight: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        background: '#0078c8',
    },
    container: {
        marginTop: 30
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));


export default function StockView(props) {
    const classes = useStyles();
    const [reFetch, setReFetch] = React.useState(false);
    const handleReFetch = (value) => {
      setReFetch(value)
    };
    return (
        <DocumentTitle title="List | SKU">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <div className={classes.action}>
                        <SkuAddFormDialog handleReFetch={handleReFetch}/>
                    </div>
                    <SkuList reFetch={reFetch} handleReFetch={handleReFetch}/>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

