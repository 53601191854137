import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {gql} from "@apollo/client/core";
import {useMutation, useQuery} from "@apollo/client";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {useSnackbar} from "notistack";

const GET_STOCK_CONTROL_VARIABLES = gql`
query{
   stockControl(id:1){
    nodeId
    id
    restockTime
    targetStock
    minimumStock
    usage
    minimumShelfQty
    minimumBlazeOrderQty
    roundOffOrders
  }
}
`;

export {GET_STOCK_CONTROL_VARIABLES}
const UPDATE_STOCK_CONTROL_VARIABLES = gql`
    mutation ($restockTime: BigFloat!, $targetStock: BigFloat!, $minimumStock: BigFloat!, $usage: Int!, $minimumShelfQty: Int!, $minimumBlazeOrderQty: Int!, $roundOffOrders: Int! ){
     updateStockControl(
       input: {
          id: 1
          patch: {
            restockTime: $restockTime
            targetStock: $targetStock
            minimumStock: $minimumStock
            usage: $usage
            minimumShelfQty: $minimumShelfQty
            minimumBlazeOrderQty: $minimumBlazeOrderQty
            roundOffOrders: $roundOffOrders
        }
      }
    ){
     stockControl{
      nodeId
      id
      restockTime
      targetStock
      minimumStock
      usage
      minimumShelfQty
      minimumBlazeOrderQty
      roundOffOrders
     }
    }
    }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    submitButton: {
        margin: theme.spacing(1),
        float: 'right'
    }
}));


const Schema = Yup.object().shape({
    restockTime: Yup.number().positive().required('Required'),
    targetStock: Yup.number().positive().required('Required'),
    minimumStock: Yup.number().positive().required('Required'),
    usage: Yup.number().positive().required('Required'),
    minimumShelfQty: Yup.number().positive().required('Required'),
    minimumBlazeOrderQty: Yup.number().positive().required('Required'),
    roundOffOrders: Yup.number().positive().required('Required'),
});



export default function OrderVariableEditForm(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { data, loading, error } = useQuery(GET_STOCK_CONTROL_VARIABLES);
    const [updateStockControl, {  loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_STOCK_CONTROL_VARIABLES, {
        refetchQueries: [{query: props.refetchQuery}],
        onCompleted: ({ updateStockControl: { stockControl } }) => {
            enqueueSnackbar(`Saved`, {variant: "success"});
            props.refetchStockOrder();
        }
    });
    React.useEffect(() => {
        mutationError && enqueueSnackbar(`Server encountered an error: ${mutationError.message}`, {variant: "error"})
    }, [mutationError]);

    return (
        <React.Fragment>
        {!loading ?
        <Formik
            initialValues={{
                restockTime: parseInt(data.stockControl.restockTime),
                targetStock: parseInt(data.stockControl.targetStock),
                minimumStock: parseInt(data.stockControl.minimumStock),
                usage: parseInt(data.stockControl.usage),
                minimumShelfQty: parseInt(data.stockControl.minimumShelfQty),
                minimumBlazeOrderQty: parseInt(data.stockControl.minimumBlazeOrderQty),
                roundOffOrders: parseInt(data.stockControl.roundOffOrders)
            }}
            validationSchema={Schema}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    setSubmitting(false);
                    updateStockControl({variables: values});

                }, 500);
            }}
        >
            {({ handleChange, errors, submitForm, isSubmitting }) => (
                <Form className={classes.root} autoComplete="off">
                    {errors.name ? (
                        <div>{errors}</div>
                    ) : null}
                    <div>
                        <Field
                            component={TextField}
                            name="restockTime"
                            type="number"
                            label="Time to restock (weeks)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="targetStock"
                            type="number"
                            label="Target stock on hand"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="minimumStock"
                            type="number"
                            label="Minimum stock on hand (weeks)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="usage"
                            type="number"
                            label="Usage average calculation period (weeks)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="minimumShelfQty"
                            type="number"
                            label="Minimum shelf quantity per SKU (blinds)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="minimumBlazeOrderQty"
                            type="number"
                            label="Blaze minimum order quantity (blinds)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div>
                        <Field
                            component={TextField}
                            name="roundOffOrders"
                            type="number"
                            label="Round orders up to nearest (blinds)"
                            onChange={e => {
                                handleChange(e);
                            }}
                        />
                    </div>
                    <div className={classes.submitButton}>
                        <Button onClick={props.handleClose} disabled={mutationLoading} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            Re Calculate
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
            :null}
        </React.Fragment>
    );
}