import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import OrderCreate from "./OrderCreate";
import Button from "@material-ui/core/Button";
import OrderMarkSent from "./OrderMarkSent";
import OrderCancel from "./OrderCancel";
import OrderCsv from "../csv/orderCsv";
const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: "1rem",
        minWidth: "275"
    },

    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});
const statusMap = {
    'Draft': ['Sent', 'Cancel'],
    'Sent': ['Draft', 'Cancel'],
    'Received': [],
    'Cancelled' : []
};




const statusColorMap = {
  'Draft': 'default',
  'Sent' : 'primary'
};

export default function OrderCreateSummary(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={1} >
            <CardContent>
                <Typography variant="h5" component="h1">
                    {" "}
                    <strong>Order Summary</strong>
                    {props.orderStatus?
                        <Button color={statusColorMap[props.orderStatus]}>{props.orderStatus}</Button>
                    :
                        null}
                </Typography>
                <Typography variant="subtitle2">
                    <hr />
                </Typography>
                <Grid container>
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                        <Typography variant="body1" component="h3">
                            Total Blinds
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Typography variant="h6" component="h3">
                            {props.records? props.records.reduce((total, record) => (record.qtyToReorder||record.qty||0) + total, 0): 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {props.orderStatus? <OrderCsv records={props.records}/> : null}
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions>
                {props.orderStatus?
                    statusMap[props.orderStatus].map(status => <OrderMarkSent key={`status-btn-${status}`} status={status} {...props}/>)
                    :
                    <OrderCreate {...props}/>
                }
            </CardActions>
        </Card>
    );
}
