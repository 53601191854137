import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import Button from '@material-ui/core/Button';
import OrderLinesTable from "../../components/orders/OrderLinesTable";
import {
    useParams
} from "react-router-dom";
import OrderDetailLinesTable from "../../components/orders/OrderDetailLinesTable";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    action: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}));

export default function OrderDetailView(props) {
    const classes = useStyles();
    let { id } = useParams();
    return (
        <DocumentTitle title="View | Order">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <OrderDetailLinesTable {...props} orderId={id}/>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

